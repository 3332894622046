import { uniqBy } from 'lodash-es';
import { handleActions } from 'redux-actions';
import store from 'store';

import { EmailStatus } from '@components/admin/emails/manuals/enum';
import {
  actionTypes as challengeTypes,
  CURRENT_CHALLENGE_KEY
} from '../templates/Challenges/redux/action-types';
import { createAcceptTermsSaga } from './accept-terms-saga';
import { actionTypes, ns as MainApp } from './action-types';
import { createAppMountSaga } from './app-mount-saga';
import { createCodeAllySaga } from './codeally-saga';
import failedUpdatesEpic from './failed-updates-epic';
import { createFetchUserSaga } from './fetch-user-saga';
import { createGaSaga } from './ga-saga';
import hardGoToEpic from './hard-go-to-epic';
import { createReportUserSaga } from './report-user-saga';
import { createSaveChallengeSaga } from './save-challenge-saga';
import { savedChallengesSelector } from './selectors';
import { actionTypes as settingsTypes } from './settings/action-types';
import { createShowCertSaga } from './show-cert-saga';
import updateCompleteEpic from './update-complete-epic';
import { createUserTokenSaga } from './user-token-saga';
import { createAuthenticationSaga } from './authentication-saga';
import { createPropsUserSaga } from './props-user-saga';
import { createTourSaga } from './tour-saga';
import { createNacionalSaga } from './nacional-saga';
import { createMessageSaga } from './message-saga';
import { createEmailsSaga } from './email-saga';

const defaultFetchState = {
  pending: true,
  complete: false,
  errored: false,
  error: null
};

const initialState = {
  appUsername: '',
  recentlyClaimedBlock: null,
  completionCount: 0,
  currentChallengeId: store.get(CURRENT_CHALLENGE_KEY),
  showCert: {},
  showCertFetchState: {
    ...defaultFetchState
  },
  showCodeAlly: false,
  user: {},
  userFetchState: {
    ...defaultFetchState
  },
  allChallengesInfo: {
    challengeEdges: [],
    certificateNodes: []
  },
  userProfileFetchState: {
    ...defaultFetchState
  },
  showSignoutModal: false,
  showModalGoToUnlockPage: false,
  isOnline: true,
  isServerOnline: true,
  codeRegistration: '',
  courseCodeInfo: null,
  userRegistrationStatus: null,
  messageList: [],
  loading: {
    signIn: false,
    signUp: false,
    subscription: false,
    sentEmailToRecovery: false
  },
  automaticEmails: {
    emailListSize: null,
    pageNumber: null,
    pageSize: null,
    emailList: [],
    loading: false
  },
  manualEmails: {
    emailListSize: null,
    pageNumber: null,
    pageSize: null,
    emailList: [],
    loading: false
  },
  recurringEmail: {
    emailListSize: null,
    pageNumber: null,
    pageSize: null,
    emailList: [],
    loading: false
  },
  showEmailDrawer: false
};

export const epics = [hardGoToEpic, failedUpdatesEpic, updateCompleteEpic];

export const sagas = [
  ...createAcceptTermsSaga(actionTypes),
  ...createAppMountSaga(actionTypes),
  ...createCodeAllySaga(actionTypes),
  ...createGaSaga(actionTypes),
  ...createFetchUserSaga(actionTypes),
  ...createShowCertSaga(actionTypes),
  ...createReportUserSaga(actionTypes),
  ...createUserTokenSaga(actionTypes),
  ...createSaveChallengeSaga(actionTypes),
  ...createAuthenticationSaga(actionTypes),
  ...createPropsUserSaga(actionTypes),
  ...createTourSaga(actionTypes),
  ...createNacionalSaga(actionTypes),
  ...createMessageSaga(actionTypes),
  ...createEmailsSaga(actionTypes)
];

function spreadThePayloadOnUser(state, payload) {
  return {
    ...state,
    user: {
      ...state.user,
      [state.appUsername]: {
        ...state.user[state.appUsername],
        ...payload
      }
    }
  };
}

export const reducer = handleActions(
  {
    [actionTypes.acceptTermsComplete]: (state, { payload }) => {
      const { appUsername } = state;
      return {
        ...state,
        user: {
          ...state.user,
          [appUsername]: {
            ...state.user[appUsername],
            // TODO: the user accepts the privacy terms in practice during auth
            // however, it's currently being used to track if they've accepted
            // or rejected the newsletter. Ideally this should be migrated,
            // since they can't sign up without accepting the terms.
            acceptedPrivacyTerms: true,
            sendQuincyEmail:
              payload === null
                ? state.user[appUsername].sendQuincyEmail
                : payload
          }
        }
      };
    },
    [actionTypes.updateAllChallengesInfo]: (state, { payload }) => ({
      ...state,
      allChallengesInfo: { ...payload }
    }),
    [actionTypes.codeRegistration]: (state, { payload }) => ({
      ...state,
      codeRegistration: payload
    }),
    [actionTypes.fetchMessagesComplete]: (state, { payload }) => ({
      ...state,
      messageList: payload
    }),
    [actionTypes.fetchUser]: state => ({
      ...state,
      userFetchState: { ...defaultFetchState }
    }),
    [actionTypes.fetchProfileForUser]: state => ({
      ...state,
      userProfileFetchState: { ...defaultFetchState }
    }),
    [actionTypes.fetchUserComplete]: (
      state,
      { payload: { user, username } }
    ) => ({
      ...state,
      user: {
        ...state.user,
        [username]: { ...user, sessionUser: true }
      },
      appUsername: username,
      currentChallengeId: user.currentChallengeId,
      userFetchState: {
        pending: false,
        complete: true,
        errored: false,
        error: null
      }
    }),
    [actionTypes.fetchUserError]: (state, { payload }) => ({
      ...state,
      userFetchState: {
        pending: false,
        complete: false,
        errored: true,
        error: payload
      }
    }),
    [actionTypes.fetchProfileForUserComplete]: (
      state,
      { payload: { user, username } }
    ) => {
      const previousUserObject =
        username in state.user ? state.user[username] : {};
      return {
        ...state,
        user: {
          ...state.user,
          [username]: { ...previousUserObject, ...user }
        },
        userProfileFetchState: {
          ...defaultFetchState,
          pending: false,
          complete: true
        }
      };
    },
    [actionTypes.fetchProfileForUserError]: (state, { payload }) => ({
      ...state,
      userProfileFetchState: {
        pending: false,
        complete: false,
        errored: true,
        error: payload
      }
    }),
    [actionTypes.onlineStatusChange]: (state, { payload: isOnline }) => ({
      ...state,
      isOnline
    }),
    [actionTypes.serverStatusChange]: (state, { payload: isServerOnline }) => ({
      ...state,
      isServerOnline
    }),
    [actionTypes.resetUserData]: state => ({
      ...state,
      appUsername: '',
      user: {}
    }),
    [actionTypes.openSignoutModal]: state => ({
      ...state,
      showSignoutModal: true
    }),
    [actionTypes.closeSignoutModal]: state => ({
      ...state,
      showSignoutModal: false
    }),
    [actionTypes.openModalGoToUnlockPage]: state => ({
      ...state,
      showModalGoToUnlockPage: true
    }),
    [actionTypes.closeModalGoToUnlockPage]: state => ({
      ...state,
      showModalGoToUnlockPage: false
    }),
    [actionTypes.showCert]: state => ({
      ...state,
      showCert: {},
      showCertFetchState: { ...defaultFetchState }
    }),
    [actionTypes.showCertComplete]: (state, { payload }) => ({
      ...state,
      showCert: payload,
      showCertFetchState: {
        ...defaultFetchState,
        pending: false,
        complete: true
      }
    }),
    [actionTypes.showCertError]: (state, { payload }) => ({
      ...state,
      showCert: {},
      showCertFetchState: {
        pending: false,
        complete: false,
        errored: true,
        error: payload
      }
    }),
    [actionTypes.submitComplete]: (state, { payload }) => {
      const { submittedChallenge, savedChallenges } = payload;
      let submittedchallenges = [
        { ...submittedChallenge, completedDate: Date.now() }
      ];
      if (submittedChallenge.challArray) {
        submittedchallenges = submittedChallenge.challArray;
      }
      const { appUsername } = state;
      return {
        ...state,
        completionCount: state.completionCount + 1,
        user: {
          ...state.user,
          [appUsername]: {
            ...state.user[appUsername],
            completedChallenges: uniqBy(
              [
                ...submittedchallenges,
                ...state.user[appUsername].completedChallenges
              ],
              'id'
            ),
            savedChallenges:
              savedChallenges ?? savedChallengesSelector(state[MainApp])
          }
        }
      };
    },
    [actionTypes.updateUserToken]: (state, { payload }) => {
      const { appUsername } = state;
      return {
        ...state,
        user: {
          ...state.user,
          [appUsername]: {
            ...state.user[appUsername],
            userToken: payload
          }
        }
      };
    },
    [actionTypes.deleteUserTokenComplete]: state => {
      const { appUsername } = state;
      return {
        ...state,
        user: {
          ...state.user,
          [appUsername]: {
            ...state.user[appUsername],
            userToken: null
          }
        }
      };
    },
    [actionTypes.hideCodeAlly]: state => {
      return {
        ...state,
        showCodeAlly: false
      };
    },
    [actionTypes.showCodeAlly]: state => {
      return {
        ...state,
        showCodeAlly: true
      };
    },
    [challengeTypes.challengeMounted]: (state, { payload }) => ({
      ...state,
      currentChallengeId: payload
    }),
    [actionTypes.saveChallengeComplete]: (state, { payload }) => {
      const { appUsername } = state;
      return {
        ...state,
        user: {
          ...state.user,
          [appUsername]: {
            ...state.user[appUsername],
            savedChallenges: payload
          }
        }
      };
    },
    [actionTypes.updateCourseCodeInfo]: (state, action) => ({
      ...state,
      courseCodeInfo: action.payload
    }),
    [actionTypes.updateUserRegistrationStatus]: (state, action) => ({
      ...state,
      userRegistrationStatus: action.payload
    }),
    [actionTypes.startLoading]: (state, { payload }) => ({
      ...state,
      loading: {
        ...state.loading,
        [payload]: true
      }
    }),
    [actionTypes.stopLoading]: (state, { payload }) => ({
      ...state,
      loading: {
        ...state.loading,
        [payload]: false
      }
    }),
    [actionTypes.fetchAutomaticEmails]: state => ({
      ...state,
      automaticEmails: {
        ...state.automaticEmails,
        loading: true
      }
    }),
    [actionTypes.fetchAutomaticEmailsComplete]: (state, { payload }) => ({
      ...state,
      automaticEmails: {
        ...state.automaticEmails,
        ...payload,
        loading: false
      }
    }),
    [actionTypes.toggleActiveAutomaticEmails]: state => ({
      ...state,
      automaticEmails: {
        ...state.automaticEmails,
        loading: true
      }
    }),
    [actionTypes.toggleActiveAutomaticEmailComplete]: (state, { payload }) => ({
      ...state,
      automaticEmails: {
        ...state.automaticEmails,
        emailList: state.automaticEmails.emailList.map(email =>
          email.id === payload.id
            ? { ...email, isActive: payload.isActive }
            : email
        ),
        loading: false
      }
    }),
    [actionTypes.editAutomaticEmail]: state => ({
      ...state,
      automaticEmails: {
        ...state.automaticEmails,
        loading: true
      }
    }),
    [actionTypes.editAutomaticEmailComplete]: (state, { payload }) => {
      return {
        ...state,
        automaticEmails: {
          ...state.automaticEmails,
          emailList: state.automaticEmails.emailList.map(email =>
            email.id === payload.id ? { ...email, ...payload.data } : email
          ),
          loading: false
        }
      };
    },
    [actionTypes.fetchManualEmails]: state => ({
      ...state,
      manualEmails: {
        ...state.manualEmails,
        loading: true
      }
    }),
    [actionTypes.fetchManualEmailsComplete]: (state, { payload }) => ({
      ...state,
      manualEmails: {
        ...state.manualEmails,
        ...payload,
        loading: false
      }
    }),
    [actionTypes.createManualEmail]: state => ({
      ...state,
      manualEmails: {
        ...state.manualEmails,
        loading: true
      }
    }),
    [actionTypes.createManualEmailComplete]: state => {
      return {
        ...state,
        manualEmails: {
          ...state.manualEmails,
          loading: false
        }
      };
    },
    [actionTypes.createManualEmailError]: state => {
      return {
        ...state,
        manualEmails: {
          ...state.manualEmails,
          loading: false
        }
      };
    },
    [actionTypes.editManualEmail]: state => ({
      ...state,
      manualEmails: {
        ...state.manualEmails,
        loading: true
      }
    }),
    [actionTypes.editManualEmailComplete]: (state, { payload }) => {
      const emailList = state.manualEmails.emailList.map(email =>
        email.id === payload.id ? { ...email, ...payload } : email
      );

      return {
        ...state,
        manualEmails: {
          ...state.manualEmails,
          emailList,
          loading: false
        }
      };
    },
    [actionTypes.cancelManualEmail]: state => ({
      ...state,
      manualEmails: {
        ...state.manualEmails,
        loading: true
      }
    }),
    [actionTypes.cancelManualEmailComplete]: (state, { payload }) => ({
      ...state,
      manualEmails: {
        ...state.manualEmails,
        emailList: state.manualEmails.emailList.map(email =>
          email.id === payload.id
            ? { ...email, isActive: false, status: EmailStatus.CANCELLED }
            : email
        ),
        loading: false
      }
    }),
    [actionTypes.fetchRecurringEmails]: state => ({
      ...state,
      recurringEmail: {
        ...state.recurringEmail,
        loading: true
      }
    }),
    [actionTypes.fetchRecurringEmailsComplete]: (state, { payload }) => ({
      ...state,
      recurringEmail: {
        ...state.recurringEmail,
        ...payload,
        loading: false
      }
    }),
    [actionTypes.createRecurringEmail]: state => ({
      ...state,
      recurringEmail: {
        ...state.recurringEmail,
        loading: true
      }
    }),
    [actionTypes.createRecurringEmailComplete]: state => {
      return {
        ...state,
        recurringEmail: {
          ...state.recurringEmail,
          loading: false
        }
      };
    },
    [actionTypes.editRecurringEmail]: state => ({
      ...state,
      recurringEmail: {
        ...state.recurringEmail,
        loading: true
      }
    }),
    [actionTypes.editRecurringEmailComplete]: (state, { payload }) => {
      const emailList = state.recurringEmail.emailList.map(email =>
        email.id === payload.id ? { ...email, ...payload } : email
      );

      return {
        ...state,
        recurringEmail: {
          ...state.recurringEmail,
          emailList,
          loading: false
        }
      };
    },
    [actionTypes.toggleActiveRecurringEmail]: state => ({
      ...state,
      recurringEmail: {
        ...state.recurringEmail,
        loading: true
      }
    }),
    [actionTypes.toggleActiveRecurringEmailComplete]: (state, { payload }) => ({
      ...state,
      recurringEmail: {
        ...state.recurringEmail,
        emailList: state.recurringEmail.emailList.map(email =>
          email.id === payload.id
            ? { ...email, isActive: payload.isActive }
            : email
        ),
        loading: false
      }
    }),
    [actionTypes.setLoadingManualEmails]: (state, { payload }) => ({
      ...state,
      manualEmails: {
        ...state.manualEmails,
        loading: payload
      }
    }),
    [actionTypes.setLoadingRecurringEmails]: (state, { payload }) => ({
      ...state,
      recurringEmail: {
        ...state.recurringEmail,
        loading: payload
      }
    }),
    [actionTypes.openEmailDrawer]: state => ({
      ...state,
      showEmailDrawer: true
    }),
    [actionTypes.closeEmailDrawer]: state => ({
      ...state,
      showEmailDrawer: false
    }),
    [settingsTypes.submitNewUsernameComplete]: (state, { payload }) =>
      payload
        ? {
            ...state,
            user: {
              ...state.user,
              [state.appUsername]: {
                ...state.user[state.appUsername],
                username: payload
              }
            }
          }
        : state,
    [settingsTypes.submitNewAboutComplete]: (state, { payload }) =>
      payload ? spreadThePayloadOnUser(state, payload) : state,
    [settingsTypes.updateMyEmailComplete]: (state, { payload }) =>
      payload ? spreadThePayloadOnUser(state, payload) : state,
    [settingsTypes.updateMySocialsComplete]: (state, { payload }) =>
      payload ? spreadThePayloadOnUser(state, payload) : state,
    [settingsTypes.updateMySoundComplete]: (state, { payload }) =>
      payload ? spreadThePayloadOnUser(state, payload) : state,
    [settingsTypes.updateMyThemeComplete]: (state, { payload }) =>
      payload ? spreadThePayloadOnUser(state, payload) : state,
    [settingsTypes.updateMyKeyboardShortcutsComplete]: (state, { payload }) =>
      payload ? spreadThePayloadOnUser(state, payload) : state,
    [settingsTypes.updateMyHonestyComplete]: (state, { payload }) =>
      payload ? spreadThePayloadOnUser(state, payload) : state,
    [settingsTypes.updateMyQuincyEmailComplete]: (state, { payload }) =>
      payload ? spreadThePayloadOnUser(state, payload) : state,
    [settingsTypes.updateMyPortfolioComplete]: (state, { payload }) =>
      payload ? spreadThePayloadOnUser(state, payload) : state,
    [settingsTypes.verifyCertComplete]: (state, { payload }) =>
      payload ? spreadThePayloadOnUser(state, payload) : state,
    [settingsTypes.submitProfileUIComplete]: (state, { payload }) =>
      payload
        ? {
            ...state,
            user: {
              ...state.user,
              [state.appUsername]: {
                ...state.user[state.appUsername],
                profileUI: { ...payload }
              }
            }
          }
        : state
  },
  initialState
);
