import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { createSelector } from 'reselect';

import Button from '../../ui/Button';
import { hardGoTo } from '../../../redux/actions';
import { closeModal } from '../../../templates/Challenges/redux/actions';
import { isNotAllowedChallengeModalOpenSelector } from '../../../templates/Challenges/redux/selectors';
import { Modal } from '../../ui/Modal';
import { ButtonTypes } from '../../ui/Button/button-types';

// Types
interface ChallengeNotAllowedModalProps {
  readonly close: () => void;
  readonly isOpen: boolean;
  readonly navigate: (location: string) => void;
}

const mapStateToProps = createSelector(
  isNotAllowedChallengeModalOpenSelector,
  (isOpen: boolean) => ({
    isOpen
  })
);

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      close: () => closeModal('notAllowedChallenge'),
      navigate: hardGoTo
    },
    dispatch
  );

function returnToLearn(
  close: () => void,
  navigate: (location: string) => void
) {
  close();
  navigate('/learn');
}

function ChallengeNotAllowedModal({
  isOpen,
  navigate
}: ChallengeNotAllowedModalProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <Modal.Root animation={true} show={isOpen}>
      <Modal.Header>
        {t('learn.not-allowed-challenge-modal.title')}
      </Modal.Header>
      <Modal.Body>
        <div>
          <p>{t('learn.not-allowed-challenge-modal.message')}</p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          buttonType={ButtonTypes.Primary}
          style={{ height: '48px', width: '100%' }}
          onClick={() => returnToLearn(close, navigate)}
        >
          {t('learn.not-allowed-challenge-modal.button')}
        </Button>
      </Modal.Footer>
    </Modal.Root>
  );
}

ChallengeNotAllowedModal.displayName = 'ChallengeNotAllowedModal';

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChallengeNotAllowedModal);
