module.exports = [{
      plugin: require('../../node_modules/.pnpm/gatsby-omni-font-loader@2.0.2_gatsby@3.15.0_react-helmet@6.1.0/node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"enableListener":true,"preconnect":["https://fonts.googleapis.com","https://fonts.gstatic.com"],"web":[{"name":"Poppins","file":"https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"},{"name":"Space Mono","file":"https://fonts.googleapis.com/css2?family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap"}]},
    },{
      plugin: require('../../node_modules/.pnpm/gatsby-plugin-google-gtag@5.12.0_gatsby@3.15.0_react-dom@16.14.0_react@16.14.0/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-FJHCRZR91S"],"pluginConfig":{"head":true,"respectDNT":false,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0},"gtagConfig":{}},
    },{
      plugin: require('../../node_modules/.pnpm/gatsby-plugin-google-tagmanager@5.11.0_gatsby@3.15.0_react-dom@16.14.0_react@16.14.0/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PBMZHQL","includeInDevelopment":true,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"enableWebVitalsTracking":true,"routeChangeEventName":"gatsby-route-change","selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../../node_modules/.pnpm/gatsby-plugin-facebook-pixel@1.0.8/node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"395500157829617"},
    },{
      plugin: require('../../node_modules/.pnpm/gatsby-plugin-manifest@3.15.0_gatsby@3.15.0_graphql@15.8.0/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"DEVstart","short_name":"dev","start_url":"/","theme_color":"#0a0a23","background_color":"#fff","display":"minimal-ui","icon":"src/assets/images/favicon.ico","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5206103ac2c4f789cf09df0fb66ae929"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
