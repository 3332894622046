import React from 'react';

function HelpFilled(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <>
      <svg
        width='21'
        height='21'
        viewBox='0 0 21 21'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M21 10.5C21 13.2848 19.8938 15.9555 17.9246 17.9246C15.9555 19.8938 13.2848 21 10.5 21C7.71523 21 5.04451 19.8938 3.07538 17.9246C1.10625 15.9555 0 13.2848 0 10.5C0 7.71523 1.10625 5.04451 3.07538 3.07538C5.04451 1.10625 7.71523 0 10.5 0C13.2848 0 15.9555 1.10625 17.9246 3.07538C19.8938 5.04451 21 7.71523 21 10.5V10.5ZM8.62313 7.91831H6.89062C6.85125 5.44294 8.7675 4.59375 10.5079 4.59375C12.3414 4.59375 14.0162 5.55187 14.0162 7.53375C14.0162 8.95125 13.1828 9.62587 12.3834 10.2336C11.4161 10.9672 11.0578 11.2416 11.0578 12.1839V12.6499H9.34106L9.33188 12.0422C9.282 10.8255 9.98156 10.0761 10.8649 9.43425C11.6392 8.8515 12.1314 8.46825 12.1314 7.63481C12.1314 6.552 11.3072 6.10181 10.4068 6.10181C9.22425 6.10181 8.62444 6.89325 8.62444 7.91831H8.62313ZM10.2651 16.3747C9.49856 16.3747 8.94075 15.8576 8.94075 15.1581C8.94075 14.4336 9.49856 13.9243 10.2664 13.9243C11.0657 13.9243 11.6156 14.4336 11.6156 15.1581C11.6156 15.8576 11.0644 16.3747 10.2651 16.3747Z'
          fill='#707070'
        />
      </svg>
    </>
  );
}

HelpFilled.displayName = 'HelpFilled';

export default HelpFilled;
