import React from 'react';

function ChevronUp2(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      width='12'
      height='8'
      viewBox='0 0 12 8'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M1.39953 7.03868L0.345703 5.98488L5.99953 0.331055L11.6534 5.98488L10.5995 7.03868L5.99953 2.43868L1.39953 7.03868Z'
        fill={props.fill ?? '#484848'}
      />
    </svg>
  );
}

ChevronUp2.displayName = 'ChevronUp2';

export default ChevronUp2;
