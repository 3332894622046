/* global preval */

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const envData = require('../../config/env.json');
const { clientLocale } = envData;

i18n.use(initReactI18next).init({
  fallbackLng: 'pt-BR',
  lng: 'pt-BR',
  // we only load one language since each language will have it's own server
  // They need to be evaluated ahead of time, to prevent Webpack from bundling
  // the entire locales directory. To avoid double imports when the locale is
  // english, we simply export nothing from the preval
  resources: {
    'pt-BR': {
      translations: preval`module.exports = require('./locales/portuguese/translations.json')`,
      intro: preval`module.exports = require('./locales/portuguese/intro.json')`,
      metaTags: preval`module.exports = require('./locales/portuguese/meta-tags.json')`,
      links: preval`module.exports = require('./locales/portuguese/links.json')`
    }
  },
  ns: ['translations', 'trending', 'intro', 'metaTags', 'links'],
  defaultNS: 'translations',
  returnObjects: true,
  // Uncomment the next line for debug logging
  // debug: true,
  interpolation: {
    escapeValue: false
  },
  react: {
    useSuspense: true
  }
});

i18n.languages = clientLocale;

export default i18n;
