import React, { ReactNode } from 'react';
import { Modal } from '@devstart/react-bootstrap';

type BodyProps = {
  readonly children: ReactNode;
};

function Body({ children }: BodyProps): JSX.Element {
  return <Modal.Body>{children}</Modal.Body>;
}

Body.displayName = 'Body';

export default Body;
