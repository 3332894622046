import { Col, Row } from '@devstart/react-bootstrap';
import React from 'react';
import type { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import type { User } from '../../../redux/prop-types';

import envData from '../../../../../config/env.json';
import { getLangCode } from '../../../../../config/i18n';
import { AvatarRenderer } from '../../helpers';
import Since from '../../../assets/icons/since';
import Location from '../../../assets/icons/location';
import Points from '../../../assets/icons/points-profile';
import SocialIcons from './social-icons';
import './camper.css';

const { clientLocale } = envData;

const localeCode = getLangCode(clientLocale);

export type CamperProps = Pick<
  User,
  | 'about'
  | 'githubProfile'
  | 'linkedin'
  | 'points'
  | 'username'
  | 'twitter'
  | 'location'
  | 'website'
  | 'picture'
  | 'name'
  | 'joinDate'
  | 'cpf'
  | 'phone'
>;

function parseDate(joinDate: string, t: TFunction): string {
  const convertedJoinDate = new Date(joinDate);
  const date = convertedJoinDate.toLocaleString([localeCode, 'en-US'], {
    year: 'numeric',
    month: 'long'
  });
  return t('profile.joined', { date: date });
}

function Camper({
  name,
  username,
  location,
  points,
  picture,
  about,
  githubProfile,
  joinDate,
  linkedin,
  twitter,
  website
}: CamperProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <div>
      <Row>
        <Col className='avatar-camper' xs={12}>
          <AvatarRenderer picture={picture} userName={username} />
        </Col>
      </Row>
      <SocialIcons
        githubProfile={githubProfile}
        linkedin={linkedin}
        twitter={twitter}
        username={username}
        website={website}
      />
      <br />
      {name && <h1 className='text-center name'>{name}</h1>}
      <p className='text-center username'>@{username}</p>
      <br />

      <Row>
        <div className='summary-profile'>
          {typeof points === 'number' ? (
            <p className='item-summary'>
              <Points className='icons-summary' />
              {points > 1
                ? t('profile.total-points_plural', { count: points })
                : t('profile.total-points', { count: points })}
            </p>
          ) : null}
          {location && (
            <p className='item-summary'>
              <Location className='icons-summary' />
              {location}
            </p>
          )}
          {joinDate && (
            <p className='item-summary'>
              <Since className='icons-summary' />
              {parseDate(joinDate, t)}
            </p>
          )}
        </div>
      </Row>

      {about && <p className='bio text-center'>{about}</p>}
    </div>
  );
}

Camper.displayName = 'Camper';

export default Camper;
