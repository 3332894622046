import React from 'react';
import { Link } from '../../helpers';
import logo from '../../../assets/images/devstart.png';
const NavLogo = (): JSX.Element => {
  return (
    <Link id='link-nav-logo' to='/learn'>
      <img src={logo} alt='logo DEVstart' width={140.87} />
    </Link>
  );
};

NavLogo.displayName = 'NavLogo';
export default NavLogo;
