import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Overlay from 'react-overlays/Overlay';
import { Tooltip } from '@devstart/react-bootstrap';
import ChevronDown2 from '../../../assets/icons/chevron-down-2';
import { AccessLevel } from '../../../utils/enums/access-levels';
import NavLinks from './nav-links';

import './menu-button.css';

export interface MenuButtonProps {
  fetchState?: { pending: boolean };
  username: string;
  userAccess: string;
}

const MenuButton = ({
  fetchState,
  username,
  userAccess
}: MenuButtonProps): JSX.Element => {
  const triggerRef = useRef(null);
  const containerRef = useRef(null);

  const [shown, setShown] = useState(false);

  const { t } = useTranslation();

  return (
    <div id='menu-container' style={{ position: 'relative' }}>
      <button
        className='button-nav'
        onClick={() => setShown(!shown)}
        ref={triggerRef}
      >
        <div className='menu-user-name'>
          <span>{username}</span>
          <ChevronDown2 fill='#484848' />
        </div>
        {userAccess === AccessLevel.LIMITED && (
          <span className='account-type'>{t('buttons.free-trial')}</span>
        )}
      </button>

      <div className='menu-overlay-container' ref={containerRef}>
        <Overlay
          show={shown}
          rootClose
          container={containerRef}
          target={triggerRef}
          onHide={() => setShown(false)}
          placement='bottom'
        >
          {({ props }) => (
            <Tooltip id='menu-tooltip'>
              <div
                id='menu-button-overlay'
                className='menu-overlay custom-position-overlay'
                {...props}
              >
                <NavLinks
                  fetchState={fetchState}
                  onHide={() => setShown(false)}
                />
              </div>
            </Tooltip>
          )}
        </Overlay>
      </div>
    </div>
  );
};

MenuButton.displayName = 'MenuButton';

export default MenuButton;
