import React from 'react';

type Props = {
  iconcolor?: string;
};

function ArrowLeft(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement> & Props
): JSX.Element {
  const { iconcolor } = props;

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.7809 6.96936C11.8507 7.03903 11.9061 7.1218 11.9439 7.21291C11.9818 7.30403 12.0012 7.40171 12.0012 7.50036C12.0012 7.59901 11.9818 7.6967 11.9439 7.78781C11.9061 7.87893 11.8507 7.9617 11.7809 8.03136L7.81038 12.0004L11.7809 15.9694C11.8506 16.0391 11.9059 16.1219 11.9437 16.213C11.9814 16.3041 12.0008 16.4017 12.0008 16.5004C12.0008 16.599 11.9814 16.6966 11.9437 16.7877C11.9059 16.8788 11.8506 16.9616 11.7809 17.0314C11.7111 17.1011 11.6284 17.1564 11.5373 17.1941C11.4461 17.2319 11.3485 17.2513 11.2499 17.2513C11.1513 17.2513 11.0536 17.2319 10.9625 17.1941C10.8714 17.1564 10.7886 17.1011 10.7189 17.0314L6.21888 12.5314C6.14903 12.4617 6.09362 12.3789 6.05581 12.2878C6.018 12.1967 5.99854 12.099 5.99854 12.0004C5.99854 11.9017 6.018 11.804 6.05581 11.7129C6.09362 11.6218 6.14903 11.539 6.21888 11.4694L10.7189 6.96936C10.7885 6.89952 10.8713 6.8441 10.9624 6.80629C11.0535 6.76849 11.1512 6.74902 11.2499 6.74902C11.3485 6.74902 11.4462 6.76849 11.5373 6.80629C11.6284 6.8441 11.7112 6.89952 11.7809 6.96936Z'
        fill={iconcolor || '#290159'}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.75 12C6.75 11.8011 6.82902 11.6103 6.96967 11.4697C7.11032 11.329 7.30109 11.25 7.5 11.25H17.25C17.4489 11.25 17.6397 11.329 17.7803 11.4697C17.921 11.6103 18 11.8011 18 12C18 12.1989 17.921 12.3897 17.7803 12.5303C17.6397 12.671 17.4489 12.75 17.25 12.75H7.5C7.30109 12.75 7.11032 12.671 6.96967 12.5303C6.82902 12.3897 6.75 12.1989 6.75 12Z'
        fill={iconcolor || '#290159'}
      />
    </svg>
  );
}

ArrowLeft.displayName = 'ArrowLeft';

export default ArrowLeft;
