import React from 'react';

function Discord(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={24}
      height={24}
      fill='none'
      {...props}
    >
      <mask
        id='a'
        width={24}
        height={24}
        x={0}
        y={0}
        maskUnits='userSpaceOnUse'
        style={{
          maskType: 'alpha'
        }}
      >
        <path fill='#D9D9D9' d='M0 0h24v24H0z' />
      </mask>
      <g mask='url(#a)'>
        <path
          fill='#5865F2'
          d='M3 12c0-4.972 4.028-9 9-9s9 4.028 9 9-4.028 9-9 9c-4.972-.01-9-4.038-9-9Z'
        />
        <path
          fill='#fff'
          d='M16.049 8.802a9.569 9.569 0 0 0-2.392-.734c-.105.188-.22.43-.304.629a8.66 8.66 0 0 0-2.654 0c-.094-.22-.2-.43-.315-.63a9.053 9.053 0 0 0-2.391.745c-1.51 2.256-1.92 4.448-1.72 6.62A9.444 9.444 0 0 0 9.21 16.91c.241-.325.45-.66.63-1.017a5.678 5.678 0 0 1-.987-.472c.084-.063.157-.126.241-.19a6.857 6.857 0 0 0 5.874 0c.084.064.158.127.242.19-.315.189-.65.346-.997.472.179.356.388.692.63 1.017a9.558 9.558 0 0 0 2.937-1.479c.23-2.517-.42-4.7-1.731-6.63Zm-5.958 5.287c-.577 0-1.039-.525-1.039-1.165s.462-1.175 1.039-1.175c.587 0 1.049.525 1.049 1.175-.01.64-.462 1.165-1.05 1.165Zm3.86 0c-.577 0-1.039-.525-1.039-1.165s.462-1.175 1.039-1.175c.587 0 1.049.525 1.038 1.175-.01.65-.461 1.165-1.038 1.165Z'
        />
      </g>
    </svg>
  );
}

Discord.displayName = 'Discord';

export default Discord;
