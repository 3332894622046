import React from 'react';

import './spinner.css';

export type SpinnerProps = {
  size?: number;
};

function Spinner({ size = 20 }: SpinnerProps): JSX.Element {
  return <div className='spinner' style={{ width: size, height: size }} />;
}

Spinner.displayName = 'Spinner';

export default Spinner;
