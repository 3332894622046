import { call, takeLatest } from 'redux-saga/effects';
import { postRegisterUserAnswer } from '../../../utils/ajax';

function* submitSaveUserAnswer({ payload }) {
  try {
    yield call(postRegisterUserAnswer, payload);
  } catch (e) {
    console.log('Erro ao registrar resposta de usuário');
  }
}

export function createUserAnswer(types) {
  return [takeLatest(types.saveUserAnswer, submitSaveUserAnswer)];
}
