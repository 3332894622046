import React, { useRef, useState } from 'react';
import { Tooltip } from '@devstart/react-bootstrap';
import { useTranslation } from 'react-i18next';
import Overlay from 'react-overlays/Overlay';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import PointsIcon from '../../../assets/icons/points-header';
import { CompletedChallenge } from '../../../redux/prop-types';
import { completedChallengesSelector } from '../../../redux/selectors';

import './points.css';

interface PointsProps {
  completedChallengeIds: string[];
}

const mapStateToProps = createSelector(
  completedChallengesSelector,
  (completedChallenges: CompletedChallenge[]) => ({
    completedChallengeIds: completedChallenges.map(({ id }) => id)
  })
);

const Points = ({ completedChallengeIds }: PointsProps): JSX.Element => {
  const { t } = useTranslation();
  const points = 50 + completedChallengeIds.length * 10;

  const triggerRef = useRef(null);
  const containerRef = useRef(null);

  const [shown, setShown] = useState(false);

  const hover = {
    onMouseEnter: () => setShown(!shown),
    onMouseLeave: () => setShown(!shown)
  };

  return (
    <div {...hover}>
      <div id='points' className='points-container' ref={triggerRef}>
        <PointsIcon fill='#484848' />
        <p className='points-number-text'>{points}</p>
      </div>

      <div className='menu-overlay-container' ref={containerRef}>
        <Overlay
          show={shown}
          rootClose
          container={containerRef}
          offset={[10, 16]}
          target={triggerRef}
          onHide={() => setShown(false)}
          placement='bottom-end'
        >
          {({ props }) => (
            <Tooltip id='point-tooltip'>
              <div {...props} className='menu-overlay' id='menu-overlay'>
                <span>{t('learn.points-description')}</span>
              </div>
            </Tooltip>
          )}
        </Overlay>
      </div>
    </div>
  );
};

Points.displayName = 'Points';

export default connect(mapStateToProps)(Points);
