import React from 'react';

type Props = {
  iconcolor?: string;
};

function CheckedCurriculum(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement> & Props
): JSX.Element {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.7819 5.46912C20.8518 5.53879 20.9072 5.62155 20.945 5.71267C20.9828 5.80379 21.0023 5.90147 21.0023 6.00012C21.0023 6.09877 20.9828 6.19645 20.945 6.28757C20.9072 6.37869 20.8518 6.46145 20.7819 6.53112L10.2819 17.0311C10.2123 17.101 10.1295 17.1564 10.0384 17.1942C9.94728 17.232 9.8496 17.2515 9.75095 17.2515C9.6523 17.2515 9.55461 17.232 9.4635 17.1942C9.37238 17.1564 9.28962 17.101 9.21995 17.0311L3.96995 11.7811C3.82912 11.6403 3.75 11.4493 3.75 11.2501C3.75 11.051 3.82912 10.86 3.96995 10.7191C4.11078 10.5783 4.30178 10.4992 4.50095 10.4992C4.70011 10.4992 4.89112 10.5783 5.03195 10.7191L9.75095 15.4396L19.7199 5.46912C19.7896 5.39928 19.8724 5.34386 19.9635 5.30605C20.0546 5.26824 20.1523 5.24878 20.2509 5.24878C20.3496 5.24878 20.4473 5.26824 20.5384 5.30605C20.6295 5.34386 20.7123 5.39928 20.7819 5.46912V5.46912Z'
        fill={props.iconcolor || '#05B522'}
      />
    </svg>
  );
}

CheckedCurriculum.displayName = 'CheckedCurriculum';

export default CheckedCurriculum;
