import React from 'react';

function Close(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='26'
      height='26'
      fill='none'
      viewBox='0 0 26 26'
      {...props}
    >
      <g filter='url(#filter0_b_3061_3709)'>
        <rect
          width='26'
          height='26'
          fill='#000'
          fillOpacity='0.2'
          rx='13'
        ></rect>
        <mask
          id='mask0_3061_3709'
          style={{ maskType: 'alpha' }}
          width='18'
          height='18'
          x='4'
          y='4'
          maskUnits='userSpaceOnUse'
        >
          <path fill='#D9D9D9' d='M4 4H22V22H4z'></path>
        </mask>
        <g mask='url(#mask0_3061_3709)'>
          <path
            fill='#fff'
            d='M8.8 17.99l-.79-.79 4.2-4.2-4.2-4.2.79-.79 4.2 4.2 4.2-4.2.79.79-4.2 4.2 4.2 4.2-.79.79-4.2-4.2-4.2 4.2z'
          ></path>
        </g>
      </g>
      <defs>
        <filter
          id='filter0_b_3061_3709'
          width='29.6'
          height='29.6'
          x='-1.8'
          y='-1.8'
          colorInterpolationFilters='sRGB'
          filterUnits='userSpaceOnUse'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
          <feGaussianBlur
            in='BackgroundImageFix'
            stdDeviation='0.9'
          ></feGaussianBlur>
          <feComposite
            in2='SourceAlpha'
            operator='in'
            result='effect1_backgroundBlur_3061_3709'
          ></feComposite>
          <feBlend
            in='SourceGraphic'
            in2='effect1_backgroundBlur_3061_3709'
            result='shape'
          ></feBlend>
        </filter>
      </defs>
    </svg>
  );
}

Close.displayName = 'Close';

export default Close;
