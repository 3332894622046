/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import i18next from 'i18next';
import { sortChallengeFiles } from '../../../../../utils/sort-challengefiles';
import { ChallengeFile, ChallengeFiles } from '../../../redux/prop-types';
import { toggleVisibleEditor } from '../redux/actions';
import {
  visibleEditorsSelector,
  challengeFilesSelector
} from '../redux/selectors';
import Button from '../../../components/ui/Button';
import { ButtonTypes } from '../../../components/ui/Button/button-types';

type VisibleEditors = {
  [key: string]: boolean;
};
interface EditorTabsProps {
  challengeFiles: ChallengeFiles;
  toggleVisibleEditor: typeof toggleVisibleEditor;
  visibleEditors: VisibleEditors;
}

const mapStateToProps = createSelector(
  visibleEditorsSelector,
  challengeFilesSelector,
  (visibleEditors: VisibleEditors, challengeFiles: ChallengeFiles) => ({
    visibleEditors,
    challengeFiles
  })
);

const mapDispatchToProps = {
  toggleVisibleEditor
};

class EditorTabs extends Component<EditorTabsProps> {
  static displayName: string;
  render() {
    const { challengeFiles, toggleVisibleEditor, visibleEditors } = this.props;
    const files: ChallengeFile[] = sortChallengeFiles(challengeFiles);
    const isMoreThanOneFile = files.length > 1 ? true : false;
    return (
      <div className='monaco-editor-tabs'>
        {isMoreThanOneFile &&
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
          sortChallengeFiles(challengeFiles).map(
            (challengeFile: ChallengeFile) => (
              <Button
                buttonType={ButtonTypes.Primary}
                aria-expanded={visibleEditors[challengeFile.fileKey] ?? 'false'}
                key={challengeFile.fileKey}
                data-cy={`editor-tab-${challengeFile.fileKey}`}
                onClick={() => toggleVisibleEditor(challengeFile.fileKey)}
              >
                {`${challengeFile.name}.${challengeFile.ext}`}{' '}
                <span className='sr-only'>
                  {i18next.t('learn.editor-tabs.editor')}
                </span>
              </Button>
            )
          )}
      </div>
    );
  }
}

EditorTabs.displayName = 'EditorTabs';

export default connect(mapStateToProps, mapDispatchToProps)(EditorTabs);
