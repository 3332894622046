import i18next from 'i18next';
import React from 'react';
import { Link } from '../../../components/helpers/index';

import './bread-crumb.css';

interface BreadCrumbProps {
  challengeTitle: string;
  block: string;
  superBlock: string;
  phase: string;
  video: boolean;
}

function BreadCrumb({
  block,
  superBlock,
  phase,
  challengeTitle,
  video
}: BreadCrumbProps): JSX.Element {
  return (
    <nav
      className={
        video ? 'container-bread-crumb-video' : 'container-bread-crumb'
      }
    >
      <div className='module-theme-path'>
        <Link
          className='path-bread-crumb'
          to={`/learn/${superBlock}`}
          title={i18next.t(`intro:${superBlock}.title`)}
        >
          {i18next.t(`intro:${superBlock}.title`)}
        </Link>
        <p className='path-bread-crumb-next'>&nbsp;{' > '}&nbsp;</p>
        <Link
          className='path-bread-crumb'
          to={`/learn/${superBlock}/#${block}`}
          title={i18next.t(`intro:${superBlock}.phases.${phase}.title`)}
        >
          {i18next.t(`intro:${superBlock}.phases.${phase}.title`)}
        </Link>
        <p className='path-bread-crumb-next'>&nbsp;{' > '}&nbsp;</p>
        <Link
          className='path-bread-crumb'
          to={`/learn/${superBlock}/#${block}`}
          title={i18next.t(
            `intro:${superBlock}.phases.${phase}.blocks.${block}.title`
          )}
        >
          {i18next.t(
            `intro:${superBlock}.phases.${phase}.blocks.${block}.title`
          )}
        </Link>
        <p className='path-bread-crumb-next'>&nbsp;{' > '}&nbsp;</p>
        <p className='path-bread-crumb-next' title={challengeTitle}>
          {challengeTitle}
        </p>
      </div>
    </nav>
  );
}

BreadCrumb.displayName = 'BreadCrumb';

export default BreadCrumb;
