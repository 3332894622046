import React from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';

import notFoundLogo from '../../assets/images/logo.svg';
import { Spacer } from '../helpers';

import Button from '../ui/Button';
import { ButtonTypes } from '../ui/Button/button-types';

import './404.css';

const FourOhFour = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div className='notfound-page-wrapper'>
      <Helmet title={t('404.page-not-found') + '| freeCodeCamp'} />
      <img alt={t('404.not-found')} src={notFoundLogo} />
      <Spacer size='medium' />
      <h1>{t('404.page-not-found')}.</h1>
      <Spacer size='medium' />
      <div>
        <p>{t('404.heres-a-quote')}</p>
      </div>
      <Spacer size='large' />
      <Button buttonType={ButtonTypes.Primary} href='/learn'>
        {t('buttons.view-curriculum')}
      </Button>
    </div>
  );
};

export default FourOhFour;
