import React from 'react';

function TestPassed(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g clipPath='url(#clip0_6945_12807)'>
        <path
          d='M16.3117 3.48813H7.68827C6.747 3.48813 6.00171 4.22661 6.00171 5.16863V7.0156C6.00171 7.79343 6.51774 8.4502 7.1904 8.65525V20.2803C7.1904 20.3129 7.20402 20.3454 7.20553 20.3794C7.27892 21.6302 8.37682 24 11.9951 24C15.3455 24 16.5925 21.7361 16.7748 20.5391C16.7877 20.4536 16.8089 20.3666 16.8089 20.2803V8.656C17.4823 8.45095 17.9983 7.79419 17.9983 7.01636V5.16863C17.9983 4.22661 17.2523 3.48813 16.3117 3.48813ZM16.2913 6.42391C16.2913 6.74472 16.031 7.00501 15.7102 7.00501H15.6837C15.3629 7.00501 15.1011 7.26529 15.1011 7.58611V20.2818C15.1011 20.2818 14.7697 22.2953 11.9966 22.2953C9.03358 22.2953 8.89285 20.2818 8.89285 20.2818V7.58535C8.89285 7.26454 8.63256 7.00425 8.31174 7.00425H8.28451C7.96369 7.00425 7.7034 6.74397 7.7034 6.42315V5.77546C7.7034 5.45465 7.96369 5.19436 8.28451 5.19436H15.7072C16.028 5.19436 16.2883 5.45465 16.2883 5.77546V6.42391H16.2913Z'
          fill='#05B522'
        />
        <path
          d='M11.998 21.4607C13.9426 21.4607 14.1703 19.8354 14.1703 19.8354V14.4534H9.82568V19.8354C9.8272 19.8331 9.92102 21.4607 11.998 21.4607Z'
          fill='#05B522'
        />
        <path
          d='M12.2948 12.0382C12.8907 12.0382 13.3737 11.5551 13.3737 10.9592C13.3737 10.3633 12.8907 9.88025 12.2948 9.88025C11.6989 9.88025 11.2158 10.3633 11.2158 10.9592C11.2158 11.5551 11.6989 12.0382 12.2948 12.0382Z'
          fill='#05B522'
        />
        <path
          d='M10.9198 8.30946C11.5161 8.30946 11.9996 7.82604 11.9996 7.22973C11.9996 6.63341 11.5161 6.14999 10.9198 6.14999C10.3235 6.14999 9.84009 6.63341 9.84009 7.22973C9.84009 7.82604 10.3235 8.30946 10.9198 8.30946Z'
          fill='#05B522'
        />
        <path
          d='M14.159 2.55215C14.7374 2.55215 15.2062 2.08331 15.2062 1.50496C15.2062 0.926609 14.7374 0.457764 14.159 0.457764C13.5807 0.457764 13.1118 0.926609 13.1118 1.50496C13.1118 2.08331 13.5807 2.55215 14.159 2.55215Z'
          fill='#05B522'
        />
        <path
          d='M10.4613 1.24392C10.8048 1.24392 11.0833 0.965461 11.0833 0.621961C11.0833 0.278461 10.8048 0 10.4613 0C10.1178 0 9.83936 0.278461 9.83936 0.621961C9.83936 0.965461 10.1178 1.24392 10.4613 1.24392Z'
          fill='#05B522'
        />
      </g>
      <defs>
        <clipPath id='clip0_6945_12807'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}

TestPassed.displayName = 'TestPassed';

export default TestPassed;
