/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { Fragment } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import {
  discordLocation,
  whatsappSupportNumber
} from '../../../../../config/env.json';

import { challengeMetaSelector } from '../redux/selectors';

import './help-menu.css';
import Discord from '../../../assets/icons/discord';
import WhatsApp from '../../../assets/icons/whatsapp';

const mapStateToProps = createSelector(
  challengeMetaSelector,
  ({
    superBlock,
    block,
    phase,
    title
  }: {
    superBlock: string;
    block: string;
    phase: string;
    title: string;
  }) => ({
    superBlock,
    block,
    phase,
    title
  })
);

export interface HelpMenuProps {
  readonly superBlock: string;
  readonly block: string;
  readonly phase: string;
  readonly title: string;
}

const mapDispatchToProps = {};

function HelpMenu({ block, phase, superBlock, title }: HelpMenuProps) {
  const { t } = useTranslation();

  const blockTitle = t(`intro:${superBlock}.title`);
  const phaseTitle = t(`intro:${superBlock}.phases.${phase}.title`);
  const themeTitle = t(
    `intro:${superBlock}.phases.${phase}.blocks.${block}.title`
  );
  const challengeTitle = title.slice(3);

  const number = String(whatsappSupportNumber);
  const infoText = t('buttons.found-an-error-message', {
    blockTitle,
    phaseTitle,
    themeTitle,
    challengeTitle
  });
  const url = `https://api.whatsapp.com/send/?phone=${number}&text=${infoText}&type=phone_number&app_absent=0`;

  return (
    <div className='help-body'>
      <Fragment key='profile-frag'>
        <div className='help-item'>
          <Discord className='nav-item-icon' />
          <a
            className='help-link'
            href={String(discordLocation)}
            target='_blank'
            rel='noreferrer'
            onClick={() => {
              window.dataLayer.push({
                event: 'gtm.click',
                'gtm.elementClasses': 'help-link-discord'
              });
            }}
          >
            {t('buttons.i-have-a-question')}
          </a>
        </div>
      </Fragment>
      <Fragment key='curriculum-frag'>
        <div className='help-item'>
          <WhatsApp className='nav-item-icon' />
          <a
            className='help-link whatsApp'
            href={url}
            target='_blank'
            rel='noreferrer'
            onClick={() => {
              window.dataLayer.push({
                event: 'gtm.formSubmit',
                'gtm.elementClasses': 'form-cadastro'
              });
              window.dataLayer.push({
                event: 'gtm.click',
                'gtm.elementClasses': 'help-link-whatsApp'
              });
            }}
          >
            {t('buttons.report-an-error')}
          </a>
        </div>
      </Fragment>
    </div>
  );
}

HelpMenu.displayName = 'HelpMenu';

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(HelpMenu));
