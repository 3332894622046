import React from 'react';

function WhatsApp(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={24}
      height={24}
      fill='none'
      {...props}
    >
      <mask
        id='a'
        width={24}
        height={24}
        x={0}
        y={0}
        maskUnits='userSpaceOnUse'
        style={{
          maskType: 'alpha'
        }}
      >
        <path fill='#D9D9D9' d='M0 0h24v24H0z' />
      </mask>
      <g mask='url(#a)'>
        <path
          fill='url(#b)'
          d='M9.595 8.279c.142.005.298.012.447.342.102.226.274.646.41.981.101.247.183.448.204.49.05.099.083.215.016.347l-.027.056c-.05.102-.087.176-.171.275l-.102.12c-.069.084-.137.167-.197.227-.1.098-.203.206-.087.404.116.198.515.847 1.107 1.372a5.148 5.148 0 0 0 1.467.924c.055.024.1.043.133.06.199.099.315.082.43-.05.117-.132.498-.579.63-.777.133-.198.266-.165.448-.099.183.066 1.16.545 1.36.645l.109.053c.138.066.232.111.272.178.05.083.05.48-.116.942-.166.463-.978.909-1.343.942l-.106.011c-.337.04-.762.09-2.281-.507-1.87-.734-3.102-2.553-3.356-2.929a2.33 2.33 0 0 0-.043-.062l-.002-.003c-.108-.143-.81-1.078-.81-2.046 0-.913.45-1.391.657-1.612l.039-.041a.732.732 0 0 1 .53-.248h.022c.126 0 .25 0 .36.005Z'
        />
        <path
          fill='url(#c)'
          fillRule='evenodd'
          d='M5.14 15.888a7.787 7.787 0 0 1-1.076-3.968C4.064 7.552 7.647 4 12.032 4c2.137 0 4.129.832 5.639 2.32A7.914 7.914 0 0 1 20 11.936c0 4.368-3.582 7.92-7.968 7.92-1.333 0-2.65-.32-3.807-.96L4 20l1.14-4.112Zm1.286-.448.16.24-.674 2.432 2.506-.656.24.144a6.564 6.564 0 0 0 3.374.928c3.647 0 6.619-2.96 6.619-6.592a6.6 6.6 0 0 0-6.619-6.592c-3.646 0-6.618 2.944-6.618 6.592 0 1.232.353 2.448 1.012 3.504Z'
          clipRule='evenodd'
        />
      </g>
      <defs>
        <linearGradient
          id='b'
          x1={4}
          x2={20}
          y1={12.001}
          y2={12.001}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#20B038' />
          <stop offset={1} stopColor='#60D66A' />
        </linearGradient>
        <linearGradient
          id='c'
          x1={4}
          x2={20}
          y1={12.001}
          y2={12.001}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#20B038' />
          <stop offset={1} stopColor='#60D66A' />
        </linearGradient>
      </defs>
    </svg>
  );
}

WhatsApp.displayName = 'WhatsApp';

export default WhatsApp;
