import React from 'react';
import { Media } from '@devstart/react-bootstrap';
import { useTranslation } from 'react-i18next';
import type { PortfolioProjectData } from '../../../redux/prop-types';

import './portfolio-projects.css';

interface PortfolioProjectsProps {
  portfolioProjects: PortfolioProjectData[];
}

export const PortfolioProjects = ({
  portfolioProjects
}: PortfolioProjectsProps): JSX.Element | null => {
  const { t } = useTranslation();
  if (!portfolioProjects.length) {
    return null;
  }
  return (
    <div className='portifolio-wrapper'>
      <h2 className='text-center'>{t('profile.portfolio')}</h2>
      {portfolioProjects.map(({ title, url, image, description, id }) => (
        <Media className='container-portifolio' key={id}>
          <Media.Body>
            <Media.Heading>
              {image && (
                <div className='portifolio-screen-container'>
                  <img
                    alt={t('profile.screen-shot', { title: title })}
                    className='portfolio-screen-shot'
                    src={image}
                  />
                </div>
              )}
              <div
                className={
                  image ? 'header-portifolio-with-image' : 'header-portifolio'
                }
              >
                <a
                  className='link-header-portifolio'
                  href={url}
                  rel='nofollow noopener noreferrer'
                >
                  {title}
                </a>
              </div>
            </Media.Heading>
            <p className='description-portifolio'>{description}</p>
          </Media.Body>
        </Media>
      ))}
    </div>
  );
};
