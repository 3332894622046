"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getLangCode = exports.rtlLangs = exports.hiddenLangs = exports.LangCodes = exports.LangNames = exports.i18nextCodes = exports.availableLangs = exports.Languages = void 0;
var Languages;
(function (Languages) {
    Languages["Portuguese"] = "portuguese";
})(Languages = exports.Languages || (exports.Languages = {}));
/*
 * List of languages with localizations enabled for builds.
 *
 * Client is the UI, and Curriculum is the Challenge Content.
 *
 * An error will be thrown if the CLIENT_LOCALE and CURRICULUM_LOCALE variables
 * from the .env file aren't found in their respective arrays below
 */
exports.availableLangs = {
    client: [Languages.Portuguese],
    curriculum: [Languages.Portuguese]
};
// ---------------------------------------------------------------------------
// Each client language needs an entry in the rest of the variables below
/* These strings set the i18next language. It needs to be the two character
 * string for the language to take advantage of available functionality.
 * Use a 639-1 code here https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
 */
exports.i18nextCodes = {
    [Languages.Portuguese]: 'pt-BR'
};
// These are for the language selector dropdown menu in the footer
exports.LangNames = {
    [Languages.Portuguese]: 'Português'
};
/* These are for formatting dates and numbers. Used with JS .toLocaleString().
 * There's an example in profile/components/Camper.js
 * List: https://github.com/unicode-cldr/cldr-dates-modern/tree/master/main
 */
exports.LangCodes = {
    [Languages.Portuguese]: 'pt-BR'
};
/**
 * This array contains languages that should NOT appear in the language selector.
 */
exports.hiddenLangs = ['arabic'];
/**
 * This array contains languages that use the RTL layouts.
 */
exports.rtlLangs = ['arabic'];
// locale is sourced from a JSON file, so we use getLangCode to
// find the associated enum values
function getLangCode(locale) {
    if (isPropertyOf(exports.LangCodes, locale))
        return exports.LangCodes[locale];
    throw new Error(`${String(locale)} is not a valid locale`);
}
exports.getLangCode = getLangCode;
function isPropertyOf(obj, key) {
    return Object.prototype.hasOwnProperty.call(obj, key);
}
