import { createTypes, createAsyncTypes } from '../utils/create-types';

export const ns = 'app';

export const actionTypes = createTypes(
  [
    'appMount',
    'hardGoTo',
    'hideCodeAlly',
    'openSignoutModal',
    'closeSignoutModal',
    'openModalGoToUnlockPage',
    'closeModalGoToUnlockPage',
    'onlineStatusChange',
    'serverStatusChange',
    'resetUserData',
    'tryToShowCodeAlly',
    'executeGA',
    'showCodeAlly',
    'submitComplete',
    'updateComplete',
    'updateFailed',
    'updateUserToken',
    'postChargeProcessing',
    'updateAllChallengesInfo',
    'saveCodeRegistration',
    'changeTourStatus',
    'updateCourseCodeInfo',
    'updateUserRegistrationStatus',
    'createMessage',
    'updateMessage',
    'fetchMessages',
    'startLoading',
    'stopLoading',
    'openEmailDrawer',
    'closeEmailDrawer',
    'setLoadingManualEmails',
    'setLoadingRecurringEmails',
    ...createAsyncTypes('fetchUser'),
    ...createAsyncTypes('postCharge'),
    ...createAsyncTypes('fetchProfileForUser'),
    ...createAsyncTypes('acceptTerms'),
    ...createAsyncTypes('showCert'),
    ...createAsyncTypes('reportUser'),
    ...createAsyncTypes('deleteUserToken'),
    ...createAsyncTypes('saveChallenge'),
    ...createAsyncTypes('signInLocal'),
    ...createAsyncTypes('signUpLocal'),
    ...createAsyncTypes('forgotPassword'),
    ...createAsyncTypes('recoveryPassword'),
    ...createAsyncTypes('verificationEmail'),
    ...createAsyncTypes('submitSubscription'),
    ...createAsyncTypes('verifyPayment'),
    ...createAsyncTypes('updatePaymentUser'),
    ...createAsyncTypes('updateAccessUtm'),
    ...createAsyncTypes('fetchCourseCodeInfo'),
    ...createAsyncTypes('checkUserRegistration'),
    ...createAsyncTypes('submitUserCertificate'),
    ...createAsyncTypes('fetchMessagesComplete'),
    ...createAsyncTypes('checkUserInactivityByEmail'),
    ...createAsyncTypes('unlockUserAccount'),
    ...createAsyncTypes('fetchAutomaticEmails'),
    ...createAsyncTypes('toggleActiveAutomaticEmail'),
    ...createAsyncTypes('editAutomaticEmail'),
    ...createAsyncTypes('fetchManualEmails'),
    ...createAsyncTypes('fetchRecurringEmails'),
    ...createAsyncTypes('createManualEmail'),
    ...createAsyncTypes('editManualEmail'),
    ...createAsyncTypes('cancelManualEmail'),
    ...createAsyncTypes('createRecurringEmail'),
    ...createAsyncTypes('editRecurringEmail'),
    ...createAsyncTypes('toggleActiveRecurringEmail')
  ],
  ns
);
