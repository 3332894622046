import { call, put, takeLatest } from 'redux-saga/effects';
import { createFlashMessage } from '../components/Flash/redux';
import {
  getAutomaticEmails,
  getManualEmails,
  toggleActiveAutomaticEmail as requestToggleActiveAutomaticEmail,
  updateAutomaticEmail,
  createManualEmail as requestCreateManualEmail,
  updateManualEmail,
  createRecurringEmail as requestCreateRecurringEmail,
  updateRecurringEmail,
  getRecurringEmails,
  toggleActiveRecurringEmail as requestToggleActiveRecurringEmail,
  cancelManualEmail as requestCancelManualEmail
} from '../utils/api/emails';
import { actionTypes } from './action-types';
import { closeEmailDrawer } from './actions';

// ==================================
// === Automatic Emails Sagas
// ==================================

function* fetchAutomaticEmails({ payload }) {
  try {
    const { result, success, message } = yield call(getAutomaticEmails, {
      filter: payload
    });

    if (success) {
      yield put({
        type: actionTypes.fetchAutomaticEmailsComplete,
        payload: result
      });
    } else {
      yield put(
        createFlashMessage({
          type: 'danger',
          message,
          variables: { dismissible: true }
        })
      );
    }
  } catch (e) {
    yield put(
      createFlashMessage({
        type: 'danger',
        message: 'Indisponível, tente novamente em alguns minutos.',
        variables: { dismissible: true }
      })
    );
  }
}

function* toggleActiveAutomaticEmail({ payload }) {
  try {
    const { success, message } = yield call(
      requestToggleActiveAutomaticEmail,
      payload
    );

    if (success) {
      yield put({
        type: actionTypes.toggleActiveAutomaticEmailComplete,
        payload
      });
    } else {
      yield put(
        createFlashMessage({
          type: 'danger',
          message,
          variables: { dismissible: true }
        })
      );
    }
  } catch (error) {
    yield put(
      createFlashMessage({
        type: 'danger',
        message: 'Indisponível, tente novamente em alguns minutos.',
        variables: { dismissible: true }
      })
    );
  }
}

function* editAutomaticEmail({ payload }) {
  try {
    const { success, message } = yield call(updateAutomaticEmail, payload);
    if (success) {
      yield put({
        type: actionTypes.editAutomaticEmailComplete,
        payload
      });

      yield put(
        createFlashMessage({
          type: 'success',
          message,
          variables: { dismissible: true }
        })
      );
    } else {
      yield put(
        createFlashMessage({
          type: 'danger',
          message,
          variables: { dismissible: true }
        })
      );
    }
  } catch (error) {
    yield put(
      createFlashMessage({
        type: 'danger',
        message: 'Indisponível, tente novamente em alguns minutos.',
        variables: { dismissible: true }
      })
    );
  }
}

// ==================================
// === Manual Emails Sagas
// ==================================

function* fetchManualEmails({ payload }) {
  try {
    const filter = {
      filter: payload
    };

    if (!filter.filter) {
      filter.filter = {
        order: 'DESC',
        pageNumber: 1,
        pageSize: 5,
        search: '',
        sort: 'sendDate'
      };
    }

    const { result, success, message } = yield call(getManualEmails, filter);

    if (success) {
      yield put({
        type: actionTypes.fetchManualEmailsComplete,
        payload: result
      });
    } else {
      yield put(
        createFlashMessage({
          type: 'danger',
          message,
          variables: { dismissible: true }
        })
      );
    }
  } catch (e) {
    yield put(
      createFlashMessage({
        type: 'danger',
        message: 'Indisponível, tente novamente em alguns minutos.',
        variables: { dismissible: true }
      })
    );
  }
}

function* createManualEmail({ payload }) {
  try {
    const { success, message } = yield call(requestCreateManualEmail, payload);

    if (success) {
      yield put(
        createFlashMessage({
          type: 'success',
          message,
          variables: { dismissible: true }
        })
      );
      yield put({
        type: actionTypes.createManualEmailComplete
      });
      yield put(closeEmailDrawer());
    } else {
      yield put({
        type: actionTypes.createManualEmailError
      });
      yield put(
        createFlashMessage({
          type: 'danger',
          message,
          variables: { dismissible: true }
        })
      );
    }
  } catch (error) {
    yield put(
      createFlashMessage({
        type: 'danger',
        message: 'Indisponível, tente novamente em alguns minutos.',
        variables: { dismissible: true }
      })
    );
  }
}

function* editManualEmail({ payload }) {
  try {
    const result = yield call(updateManualEmail, {
      id: payload.id,
      data: payload
    });

    const { success, message } = result;

    if (success) {
      yield put({
        type: actionTypes.editManualEmailComplete,
        payload: result.result
      });

      yield put(
        createFlashMessage({
          type: 'success',
          message,
          variables: { dismissible: true }
        })
      );
      yield put(closeEmailDrawer());
    } else {
      yield put(
        createFlashMessage({
          type: 'danger',
          message,
          variables: { dismissible: true }
        })
      );
    }
  } catch (error) {
    yield put(
      createFlashMessage({
        type: 'danger',
        message: 'Indisponível, tente novamente em alguns minutos.',
        variables: { dismissible: true }
      })
    );
  }
}

function* cancelManualEmail({ payload }) {
  try {
    const { success, message } = yield call(requestCancelManualEmail, payload);
    if (success) {
      yield put({
        type: actionTypes.cancelManualEmailComplete,
        payload
      });

      yield put(
        createFlashMessage({
          type: 'success',
          message,
          variables: { dismissible: true }
        })
      );

      yield put(closeEmailDrawer());
    } else {
      yield put(
        createFlashMessage({
          type: 'danger',
          message,
          variables: { dismissible: true }
        })
      );
    }
  } catch (error) {
    console.log('error', error);

    yield put(
      createFlashMessage({
        type: 'danger',
        message: 'Indisponível, tente novamente em alguns minutos.',
        variables: { dismissible: true }
      })
    );
  }
}

// ==================================
// === Recurring Emails Sagas
// ==================================

function* fetchRecurringEmails({ payload }) {
  try {
    const filter = {
      filter: payload
    };

    if (!filter.filter) {
      filter.filter = {
        pageNumber: 1,
        pageSize: 5,
        sort: 'startAt',
        order: 'DESC'
      };
    }

    const { result, success, message } = yield call(getRecurringEmails, filter);
    if (success) {
      yield put({
        type: actionTypes.fetchRecurringEmailsComplete,
        payload: result
      });
    } else {
      yield put(
        createFlashMessage({
          type: 'danger',
          message,
          variables: { dismissible: true }
        })
      );
    }
  } catch (e) {
    yield put(
      createFlashMessage({
        type: 'danger',
        message: 'Indisponível, tente novamente em alguns minutos.',
        variables: { dismissible: true }
      })
    );
  }
}

function* createRecurringEmail({ payload }) {
  try {
    const { success, message } = yield call(
      requestCreateRecurringEmail,
      payload
    );
    if (success) {
      yield put({
        type: actionTypes.createRecurringEmailComplete,
        payload
      });

      yield put({
        type: actionTypes.fetchRecurringEmails
      });

      yield put(
        createFlashMessage({
          type: 'success',
          message,
          variables: { dismissible: true }
        })
      );
      yield put(closeEmailDrawer());
    } else {
      yield put({
        type: actionTypes.fetchRecurringEmails
      });
      yield put(
        createFlashMessage({
          type: 'danger',
          message,
          variables: { dismissible: true }
        })
      );
    }
  } catch (error) {
    yield put(
      createFlashMessage({
        type: 'danger',
        message: 'Indisponível, tente novamente em alguns minutos.',
        variables: { dismissible: true }
      })
    );
  }
}

function* editRecurringEmail({ payload }) {
  try {
    const result = yield call(updateRecurringEmail, {
      id: payload.id,
      data: payload
    });

    const { success, message } = result;

    if (success) {
      yield put({
        type: actionTypes.editRecurringEmailComplete,
        payload
      });

      yield put(
        createFlashMessage({
          type: 'success',
          message,
          variables: { dismissible: true }
        })
      );
      yield put(closeEmailDrawer());
    } else {
      yield put(
        createFlashMessage({
          type: 'danger',
          message,
          variables: { dismissible: true }
        })
      );
    }
  } catch (error) {
    console.log('editRecurringEmail error', error);

    yield put(
      createFlashMessage({
        type: 'danger',
        message: 'Indisponível, tente novamente em alguns minutos.',
        variables: { dismissible: true }
      })
    );
  }
}

function* toggleActiveRecurringEmail({ payload }) {
  try {
    const { success, message } = yield call(
      requestToggleActiveRecurringEmail,
      payload
    );
    if (success) {
      yield put({
        type: actionTypes.toggleActiveRecurringEmailComplete,
        payload
      });
    } else {
      yield put(
        createFlashMessage({
          type: 'danger',
          message,
          variables: { dismissible: true }
        })
      );
    }
  } catch (error) {
    yield put(
      createFlashMessage({
        type: 'danger',
        message: 'Indisponível, tente novamente em alguns minutos.',
        variables: { dismissible: true }
      })
    );
  }
}

export function createEmailsSaga(types) {
  return [
    takeLatest(types.fetchAutomaticEmails, fetchAutomaticEmails),
    takeLatest(types.toggleActiveAutomaticEmail, toggleActiveAutomaticEmail),
    takeLatest(types.editAutomaticEmail, editAutomaticEmail),
    takeLatest(types.fetchManualEmails, fetchManualEmails),
    takeLatest(types.createManualEmail, createManualEmail),
    takeLatest(types.createManualEmailComplete, fetchManualEmails),
    takeLatest(types.editManualEmail, editManualEmail),
    takeLatest(types.cancelManualEmail, cancelManualEmail),
    takeLatest(types.fetchRecurringEmails, fetchRecurringEmails),
    takeLatest(types.createRecurringEmail, createRecurringEmail),
    takeLatest(types.createRecurringEmailComplete, fetchRecurringEmails),
    takeLatest(types.editRecurringEmail, editRecurringEmail),
    takeLatest(types.toggleActiveRecurringEmail, toggleActiveRecurringEmail)
  ];
}
