import React from 'react';
import { useTranslation } from 'react-i18next';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import { User } from '../../../redux/prop-types';
import { isSignedInSelector, userSelector } from '../../../redux/selectors';
import MenuButton from './menu-button';
import NavLogo from './nav-logo';
import './universal-nav.css';
import Points from './points';

const mapStateToProps = createSelector(
  isSignedInSelector,
  userSelector,
  (isSignedIn, userData: User) => ({
    isSignedIn,
    userData
  })
);

interface UniversalNavProps {
  fetchState?: { pending: boolean };
  isSignedIn?: boolean;
  userData: User;
}

export const UniversalNav = ({
  userData,
  fetchState,
  isSignedIn
}: UniversalNavProps): JSX.Element => {
  const { t } = useTranslation();

  const userFirstname = userData?.name?.split(' ')[0];

  return (
    <nav
      aria-label={t('aria.primary-nav')}
      className={'universal-nav'}
      id='universal-nav'
    >
      <div className={'universal-nav-left'}></div>
      <div className='universal-nav-middle'>
        <div id='universal-nav-logo'>
          <NavLogo />
        </div>
      </div>
      <div className='universal-nav-right'>
        {isSignedIn && userData.subscription && (
          <>
            <Points />
            <div id='menu-button-container'>
              <MenuButton
                username={userFirstname}
                userAccess={userData.access}
                fetchState={fetchState}
              />
            </div>
          </>
        )}
      </div>
    </nav>
  );
};

UniversalNav.displayName = 'UniversalNav';

export default connect(mapStateToProps)(UniversalNav);
