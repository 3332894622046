import './modal.css';

import Root from './root';
import Header from './header';
import Body from './body';
import Footer from './footer';

export const Modal = {
  Root,
  Header,
  Body,
  Footer
};
