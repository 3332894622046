import React from 'react';

function CheckedHome(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <>
      <svg
        width='21'
        height='15'
        viewBox='0 0 21 15'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M20.2446 0.880599C20.3261 0.961879 20.3908 1.05844 20.4349 1.16474C20.479 1.27104 20.5017 1.38501 20.5017 1.5001C20.5017 1.61519 20.479 1.72915 20.4349 1.83546C20.3908 1.94176 20.3261 2.03832 20.2446 2.1196L7.99463 14.3696C7.91335 14.4511 7.81679 14.5157 7.71049 14.5598C7.60418 14.604 7.49022 14.6267 7.37513 14.6267C7.26004 14.6267 7.14607 14.604 7.03977 14.5598C6.93347 14.5157 6.83691 14.4511 6.75563 14.3696L0.630629 8.2446C0.466327 8.0803 0.374023 7.85746 0.374023 7.6251C0.374023 7.39274 0.466327 7.1699 0.630629 7.0056C0.79493 6.8413 1.01777 6.74899 1.25013 6.74899C1.48249 6.74899 1.70533 6.8413 1.86963 7.0056L7.37513 12.5128L19.0056 0.880599C19.0869 0.799114 19.1835 0.734464 19.2898 0.690352C19.3961 0.646241 19.51 0.623535 19.6251 0.623535C19.7402 0.623535 19.8542 0.646241 19.9605 0.690352C20.0668 0.734464 20.1633 0.799114 20.2446 0.880599Z'
          fill='url(#paint0_linear_2626_1919)'
        />
        <defs>
          <linearGradient
            id='paint0_linear_2626_1919'
            x1='0.065615'
            y1='0.229699'
            x2='25.8953'
            y2='11.2687'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#FF8917' />
            <stop offset='0.473958' stopColor='#ED2885' />
            <stop offset='1' stopColor='#032BDF' />
          </linearGradient>
        </defs>
      </svg>
    </>
  );
}

CheckedHome.displayName = 'CheckedHome';

export default CheckedHome;
