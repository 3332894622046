import { call, put, takeLatest } from 'redux-saga/effects';
import { createFlashMessage } from '../components/Flash/redux';
import { putChangeTourStatus } from '../utils/ajax';
import { fetchUser } from './actions';

function* submitChangeTourStatus({ payload }) {
  try {
    const { data } = yield call(putChangeTourStatus, payload);

    if (data.success) {
      yield put(fetchUser());
    }

    if (data.error) {
      yield put(createFlashMessage({ type: 'danger', message: data.message }));
    }
  } catch (e) {
    yield put(
      createFlashMessage({
        type: 'danger',
        message: 'Indisponível, tente novamente em alguns minutos.'
      })
    );
  }
}

export function createTourSaga(types) {
  return [takeLatest(types.changeTourStatus, submitChangeTourStatus)];
}
