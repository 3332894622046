import { call, put, takeLatest } from 'redux-saga/effects';
import { createFlashMessage } from '../components/Flash/redux';
import {
  getCheckUserFirstRegistration,
  getCourseCodeInfo,
  putUserCertificate
} from '../utils/ajax';
import { openModal } from '../templates/Challenges/redux/actions';
import { defaultUrl } from '../../../config/env.json';
import { actionTypes } from './action-types';
import { fetchUser } from './actions';

function* fetchCourseCodeInfoByUf({ payload }) {
  try {
    const { data } = yield call(getCourseCodeInfo, payload.userUF);

    if (data?.result?.success) {
      const defaultRegistarionUrl = defaultUrl;

      const link =
        data?.result?.result?.data?.length > 0 &&
        data.result.result.data[0].registrationUrl
          ? data.result.result.data[0].registrationUrl
          : defaultRegistarionUrl;

      yield put({
        type: actionTypes.updateCourseCodeInfo,
        payload: { ...data.result.result.data[0], registrationUrl: link }
      });
    }
  } catch (e) {
    console.log('error', e);
    yield put(
      createFlashMessage({
        type: 'danger',
        message: 'Indisponível, tente novamente em alguns minutos.'
      })
    );
  }
}

function* checkUserRegistration({ payload }) {
  try {
    const response = yield call(getCheckUserFirstRegistration, payload);

    if (response) {
      yield put({
        type: actionTypes.updateUserRegistrationStatus,
        payload: response.data
      });

      if (!response?.data?.Row?.length) {
        yield put(openModal('nacionalCertificate'));
      }

      if (response?.data?.Row?.length > 0 && response.data.Row[0].RA !== '') {
        yield put(fetchUser());
        yield put(openModal('nacionalCertificate'));
      }
    }
  } catch (e) {
    console.log('error', e);
    yield put(
      createFlashMessage({
        type: 'danger',
        message: 'Indisponível, tente novamente em alguns minutos.'
      })
    );
  }
}

function* submitUserCertificate({ payload }) {
  try {
    yield call(putUserCertificate, payload);
  } catch (e) {
    console.log('submitUserCertificate error', e);
  }
}

export function createNacionalSaga(types) {
  return [
    takeLatest(types.fetchCourseCodeInfo, fetchCourseCodeInfoByUf),
    takeLatest(types.checkUserRegistration, checkUserRegistration),
    takeLatest(types.submitUserCertificate, submitUserCertificate)
  ];
}
