import React from 'react';

function Profile(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      width='21'
      height='21'
      viewBox='0 0 21 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.9375 18.375C3.9375 18.375 2.625 18.375 2.625 17.0625C2.625 15.75 3.9375 11.8125 10.5 11.8125C17.0625 11.8125 18.375 15.75 18.375 17.0625C18.375 18.375 17.0625 18.375 17.0625 18.375H3.9375ZM10.5 10.5C11.5443 10.5 12.5458 10.0852 13.2842 9.34673C14.0227 8.60831 14.4375 7.60679 14.4375 6.5625C14.4375 5.51821 14.0227 4.51669 13.2842 3.77827C12.5458 3.03984 11.5443 2.625 10.5 2.625C9.45571 2.625 8.45419 3.03984 7.71577 3.77827C6.97734 4.51669 6.5625 5.51821 6.5625 6.5625C6.5625 7.60679 6.97734 8.60831 7.71577 9.34673C8.45419 10.0852 9.45571 10.5 10.5 10.5Z'
        fill='#727272'
      />
    </svg>
  );
}

Profile.displayName = 'Profile';

export default Profile;
