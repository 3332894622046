import React from 'react';

type Props = {
  iconcolor?: string;
};

function ArrowRight(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement> & Props
): JSX.Element {
  const { iconcolor } = props;

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.2189 6.96936C12.2885 6.89952 12.3713 6.8441 12.4624 6.80629C12.5535 6.76849 12.6512 6.74902 12.7499 6.74902C12.8485 6.74902 12.9462 6.76849 13.0373 6.80629C13.1284 6.8441 13.2112 6.89952 13.2809 6.96936L17.7809 11.4694C17.8507 11.539 17.9061 11.6218 17.9439 11.7129C17.9818 11.804 18.0012 11.9017 18.0012 12.0004C18.0012 12.099 17.9818 12.1967 17.9439 12.2878C17.9061 12.3789 17.8507 12.4617 17.7809 12.5314L13.2809 17.0314C13.14 17.1722 12.949 17.2513 12.7499 17.2513C12.5507 17.2513 12.3597 17.1722 12.2189 17.0314C12.078 16.8905 11.9989 16.6995 11.9989 16.5004C11.9989 16.3012 12.078 16.1102 12.2189 15.9694L16.1894 12.0004L12.2189 8.03136C12.149 7.9617 12.0936 7.87893 12.0558 7.78781C12.018 7.6967 11.9985 7.59901 11.9985 7.50036C11.9985 7.40171 12.018 7.30403 12.0558 7.21291C12.0936 7.1218 12.149 7.03903 12.2189 6.96936Z'
        fill={iconcolor || '#290159'}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6 12C6 11.8011 6.07902 11.6103 6.21967 11.4697C6.36032 11.329 6.55109 11.25 6.75 11.25H16.5C16.6989 11.25 16.8897 11.329 17.0303 11.4697C17.171 11.6103 17.25 11.8011 17.25 12C17.25 12.1989 17.171 12.3897 17.0303 12.5303C16.8897 12.671 16.6989 12.75 16.5 12.75H6.75C6.55109 12.75 6.36032 12.671 6.21967 12.5303C6.07902 12.3897 6 12.1989 6 12Z'
        fill={iconcolor || '#290159'}
      />
    </svg>
  );
}

ArrowRight.displayName = 'ArrowRight';

export default ArrowRight;
