import React from 'react';

function LockOpen(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={24}
      height={24}
      fill='none'
      {...props}
    >
      <mask
        id='a'
        width={24}
        height={24}
        x={0}
        y={0}
        maskUnits='userSpaceOnUse'
        style={{
          maskType: 'alpha'
        }}
      >
        <path fill='#D9D9D9' d='M0 0h24v24H0z' />
      </mask>
      <g mask='url(#a)'>
        <path
          fill='#484848'
          d='M6.308 8.5H15v-2c0-.833-.292-1.542-.875-2.125A2.893 2.893 0 0 0 12 3.5c-.833 0-1.542.292-2.125.875A2.893 2.893 0 0 0 9 6.5H7.5c0-1.249.438-2.31 1.313-3.186C9.69 2.438 10.751 2 12 2c1.249 0 2.31.438 3.187 1.314.875.875 1.313 1.937 1.313 3.186v2h1.192c.499 0 .925.177 1.278.53.353.353.53.779.53 1.278v9.384c0 .499-.177.925-.53 1.278-.353.353-.779.53-1.278.53H6.308c-.499 0-.925-.177-1.278-.53a1.742 1.742 0 0 1-.53-1.278v-9.384c0-.499.177-.925.53-1.278.353-.353.779-.53 1.278-.53Zm0 11.5h11.384a.3.3 0 0 0 .221-.087.3.3 0 0 0 .087-.22v-9.385a.3.3 0 0 0-.087-.221.3.3 0 0 0-.22-.087H6.307a.3.3 0 0 0-.221.087.3.3 0 0 0-.087.22v9.385a.3.3 0 0 0 .087.221.3.3 0 0 0 .22.087Z'
        />
      </g>
    </svg>
  );
}

LockOpen.displayName = 'LockOpen';

export default LockOpen;
