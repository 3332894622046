import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { Test } from '../../../redux/prop-types';

import { challengeTestsSelector } from '../redux/selectors';
import TestSuite from './test-suite';

import './side-panel.css';

const mapStateToProps = createSelector(
  challengeTestsSelector,
  (tests: Test[]) => ({
    tests
  })
);
interface TestSuiteProps {
  tests: Test[];
}

export function TestSuitePanel({ tests }: TestSuiteProps): JSX.Element {
  return (
    <div id='instructions-panel-tests' className='instructions-panel'>
      <TestSuite tests={tests} />
    </div>
  );
}

TestSuitePanel.displayName = 'TestSuitePanel';

export default connect(mapStateToProps)(TestSuitePanel);
