import TagManager from 'react-gtm-module';

import envData from '../../../config/env.json';

const { GoogleTagManagerId } = envData;

const analyticsIDSelector = () => {
  return GoogleTagManagerId;
};

const gtmId = analyticsIDSelector();

if (typeof document !== `undefined`) {
  TagManager.initialize({ gtmId });
}

export default TagManager;
