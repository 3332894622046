import React from 'react';

function AccountCircle(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={24}
      height={24}
      fill='none'
      {...props}
    >
      <mask
        id='a'
        width={24}
        height={24}
        x={0}
        y={0}
        maskUnits='userSpaceOnUse'
        style={{
          maskType: 'alpha'
        }}
      >
        <path fill='#D9D9D9' d='M0 0h24v24H0z' />
      </mask>
      <g mask='url(#a)'>
        <path
          fill='#484848'
          d='M6.023 17.292c.85-.63 1.776-1.129 2.778-1.494A9.264 9.264 0 0 1 12 15.25c1.13 0 2.197.183 3.199.548 1.002.365 1.928.864 2.778 1.494a7.882 7.882 0 0 0 1.478-2.373A7.714 7.714 0 0 0 20 12c0-2.217-.78-4.104-2.337-5.663C16.104 4.78 14.217 4 12 4s-4.104.78-5.663 2.337C4.78 7.896 4 9.783 4 12c0 1.047.182 2.02.545 2.92.364.898.856 1.689 1.478 2.372ZM12 12.75c-.913 0-1.683-.313-2.31-.94s-.94-1.397-.94-2.31.313-1.683.94-2.31 1.397-.94 2.31-.94 1.683.313 2.31.94.94 1.397.94 2.31-.313 1.683-.94 2.31-1.397.94-2.31.94Zm0 8.75a9.31 9.31 0 0 1-3.713-.744 9.54 9.54 0 0 1-3.016-2.027 9.54 9.54 0 0 1-2.027-3.016A9.31 9.31 0 0 1 2.5 12c0-1.32.248-2.557.744-3.713a9.54 9.54 0 0 1 2.027-3.016 9.54 9.54 0 0 1 3.016-2.027A9.31 9.31 0 0 1 12 2.5a9.31 9.31 0 0 1 3.713.744 9.54 9.54 0 0 1 3.016 2.027 9.54 9.54 0 0 1 2.027 3.016A9.31 9.31 0 0 1 21.5 12a9.31 9.31 0 0 1-.744 3.713 9.54 9.54 0 0 1-2.027 3.016 9.54 9.54 0 0 1-3.016 2.027A9.31 9.31 0 0 1 12 21.5Zm0-1.5c.902 0 1.773-.145 2.61-.436a7.405 7.405 0 0 0 2.232-1.218 7.607 7.607 0 0 0-2.203-1.175A8.081 8.081 0 0 0 12 16.75a8.21 8.21 0 0 0-2.644.416 7.251 7.251 0 0 0-2.198 1.18c.65.522 1.394.928 2.231 1.218.838.29 1.708.436 2.611.436Zm0-8.75c.497 0 .913-.167 1.248-.502.335-.335.502-.75.502-1.248 0-.497-.167-.913-.502-1.248-.335-.335-.75-.502-1.248-.502s-.914.167-1.248.502c-.335.335-.502.75-.502 1.248 0 .497.167.913.502 1.248.334.335.75.502 1.248.502Z'
        />
      </g>
    </svg>
  );
}

AccountCircle.displayName = 'AccountCircle';

export default AccountCircle;
