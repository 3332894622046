import React from 'react';

function Help2(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={24}
      height={24}
      fill='none'
      {...props}
    >
      <mask
        id='a'
        width={24}
        height={24}
        x={0}
        y={0}
        maskUnits='userSpaceOnUse'
        style={{
          maskType: 'alpha'
        }}
      >
        <path fill='#D9D9D9' d='M0 0h24v24H0z' />
      </mask>
      <g mask='url(#a)'>
        <path
          fill='#484848'
          d='M11.989 17.615a.983.983 0 0 0 .723-.295.985.985 0 0 0 .296-.724.983.983 0 0 0-.296-.724.985.985 0 0 0-.724-.295.983.983 0 0 0-.724.296.986.986 0 0 0-.295.724c0 .285.099.526.296.723a.986.986 0 0 0 .724.295Zm-.708-3.58h1.407c.013-.493.085-.886.217-1.18.131-.294.45-.684.957-1.17.44-.44.776-.846 1.01-1.219.234-.372.351-.812.351-1.32 0-.86-.31-1.532-.928-2.016-.618-.484-1.35-.726-2.195-.726-.835 0-1.525.223-2.072.668-.547.446-.937.97-1.17 1.574l1.284.515c.122-.332.33-.655.625-.97.295-.314.733-.472 1.314-.472.59 0 1.028.162 1.31.486.283.324.424.68.424 1.068 0 .34-.096.65-.29.933-.194.282-.44.554-.74.817-.657.592-1.072 1.065-1.245 1.417-.173.353-.26.884-.26 1.595ZM12 21.5a9.255 9.255 0 0 1-3.704-.748 9.598 9.598 0 0 1-3.018-2.03 9.591 9.591 0 0 1-2.03-3.016 9.245 9.245 0 0 1-.749-3.704c0-1.314.25-2.55.748-3.705a9.597 9.597 0 0 1 2.03-3.018 9.592 9.592 0 0 1 3.016-2.03 9.245 9.245 0 0 1 3.704-.749c1.314 0 2.55.25 3.705.748a9.597 9.597 0 0 1 3.018 2.03 9.592 9.592 0 0 1 2.03 3.016 9.245 9.245 0 0 1 .749 3.704c0 1.314-.25 2.55-.748 3.705a9.598 9.598 0 0 1-2.03 3.018 9.592 9.592 0 0 1-3.016 2.03 9.245 9.245 0 0 1-3.704.749ZM12 20c2.233 0 4.125-.775 5.675-2.325C19.225 16.125 20 14.233 20 12c0-2.233-.775-4.125-2.325-5.675C16.125 4.775 14.233 4 12 4c-2.233 0-4.125.775-5.675 2.325C4.775 7.875 4 9.767 4 12c0 2.233.775 4.125 2.325 5.675C7.875 19.225 9.767 20 12 20Z'
        />
      </g>
    </svg>
  );
}

Help2.displayName = 'Help2';

export default Help2;
