import React from 'react';
import { useTranslation } from 'react-i18next';

import Lab365 from '../../assets/icons/lab365';
import InstagramLogo from '../../assets/icons/instagram-logo';
import LinkedInLogo from '../../assets/icons/linkedin-logo';

import './footer.css';

function Footer(): JSX.Element {
  const { t } = useTranslation();

  return (
    <footer className='site-footer'>
      <div className='footer-row'>
        <Lab365 />
        <div className='footer-social-media'>
          <p>{t('footer.follow-us')}</p>
          <a
            href='https://www.instagram.com/lab365_/'
            target='_blank'
            rel='noreferrer'
          >
            <InstagramLogo />
          </a>
          <a
            href='https://www.linkedin.com/company/lab365/'
            target='_blank'
            rel='noreferrer'
          >
            <LinkedInLogo />
          </a>
        </div>
      </div>
    </footer>
  );
}

Footer.displayName = 'Footer';
export default Footer;
