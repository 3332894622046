import React from 'react';

function ChevronDown2(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      width='12'
      height='8'
      viewBox='0 0 12 8'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M5.99953 7.03868L0.345703 1.38488L1.39953 0.331055L5.99953 4.93105L10.5995 0.331055L11.6534 1.38488L5.99953 7.03868Z'
        fill={props.fill ?? '#484848'}
      />
    </svg>
  );
}

ChevronDown2.displayName = 'ChevronDown2';

export default ChevronDown2;
