import { first } from 'lodash-es';
import React, { useState, useEffect, ReactElement } from 'react';
import { ReflexContainer, ReflexSplitter, ReflexElement } from 'react-reflex';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import { sortChallengeFiles } from '../../../../../utils/sort-challengefiles';
import {
  ChallengeFile,
  ChallengeFiles,
  ChangeTourStatusProps,
  ResizeProps,
  User
} from '../../../redux/prop-types';
import { setShowPreviewPortal, setShowPreviewPane } from '../redux/actions';
import { changeTourStatus } from '../../../redux/actions';
import {
  createFlashMessage,
  removeFlashMessage
} from '../../../components/Flash/redux';
import {
  portalWindowSelector,
  showPreviewPortalSelector,
  showPreviewPaneSelector,
  isAdvancingToChallengeSelector
} from '../redux/selectors';
import PreviewPortal from '../components/preview-portal';
import BreadCrumb from '../components/bread-crumb';
import { userSelector } from '../../../redux/selectors';
import { exerciseTour } from '../../../components/Tour/steps';
import { FlashMessages } from '../../../components/Flash/redux/flash-messages';
import ActionRow from './action-row';

type Pane = { flex: number };

interface DesktopLayoutProps {
  block: string;
  superBlock: string;
  phase: string;
  title: string;
  slug: string;
  challengeFiles: ChallengeFiles;
  challengeType: number;
  editor: ReactElement | null;
  hasEditableBoundaries: boolean;
  hasNotes: boolean;
  hasPreview: boolean;
  instructions: ReactElement;
  testSuite: ReactElement;
  isAdvancing: boolean;
  isFirstStep: boolean;
  layoutState: {
    codePane: Pane;
    editorPane: Pane;
    instructionPane: Pane;
    testSuitePane: Pane;
    notesPane: Pane;
    previewPane: Pane;
    testsPane: Pane;
  };
  notes: ReactElement;
  preview: ReactElement;
  resizeProps: ResizeProps;
  testOutput: ReactElement;
  windowTitle: string;
  showPreviewPortal: boolean;
  showPreviewPane: boolean;
  setShowPreviewPortal: (arg: boolean) => void;
  setShowPreviewPane: (arg: boolean) => void;
  portalWindow: null | Window;
  user: User;
  changeTourStatus: ChangeTourStatusProps;
  createFlashMessage: typeof createFlashMessage;
  removeFlashMessage: typeof removeFlashMessage;
}

const reflexProps = {
  propagateDimensions: true
};

const mapDispatchToProps = {
  setShowPreviewPortal,
  setShowPreviewPane,
  changeTourStatus,
  createFlashMessage,
  removeFlashMessage
};

const mapStateToProps = createSelector(
  isAdvancingToChallengeSelector,
  showPreviewPortalSelector,
  showPreviewPaneSelector,
  portalWindowSelector,
  userSelector,
  (
    isAdvancing: boolean,
    showPreviewPortal: boolean,
    showPreviewPane: boolean,
    portalWindow: null | Window,
    user: User
  ) => ({
    isAdvancing,
    showPreviewPortal,
    showPreviewPane,
    portalWindow,
    user
  })
);

const DesktopLayout = (props: DesktopLayoutProps): JSX.Element => {
  const {
    showPreviewPane,
    showPreviewPortal,
    setShowPreviewPane,
    setShowPreviewPortal,
    portalWindow,
    user,
    changeTourStatus,
    createFlashMessage,
    removeFlashMessage
  } = props;

  const [showNotes, setShowNotes] = useState(false);
  const [showConsole, setShowConsole] = useState(true);
  const [showInstructions, setShowInstuctions] = useState(true);
  const [showTests, setShowTests] = useState(true);

  const togglePane = (pane: string): void => {
    switch (pane) {
      case 'showPreviewPane':
        if (!showPreviewPane && showPreviewPortal) setShowPreviewPortal(false);
        setShowPreviewPane(!showPreviewPane);
        portalWindow?.close();
        break;
      case 'showPreviewPortal':
        if (!showPreviewPortal && showPreviewPane) setShowPreviewPane(false);
        setShowPreviewPortal(!showPreviewPortal);
        if (showPreviewPortal) portalWindow?.close();
        break;
      case 'showConsole':
        setShowConsole(!showConsole);
        break;
      case 'showNotes':
        setShowNotes(!showNotes);
        break;
      case 'showInstructions':
        setShowInstuctions(!showInstructions);
        break;
      case 'showTests':
        setShowTests(!showTests);
        break;
      default:
        setShowInstuctions(true);
        setShowConsole(true);
        setShowTests(true);
        setShowPreviewPane(true);
        setShowPreviewPortal(false);
        setShowNotes(false);
    }
  };

  const getChallengeFile = () => {
    const { challengeFiles } = props;
    return first(sortChallengeFiles(challengeFiles) as ChallengeFile[]);
  };

  const {
    block,
    superBlock,
    phase,
    title,
    slug,
    challengeType,
    resizeProps,
    instructions,
    testSuite,
    editor,
    testOutput,
    hasNotes,
    hasPreview,
    isAdvancing,
    isFirstStep,
    layoutState,
    notes,
    preview,
    hasEditableBoundaries,
    windowTitle
  } = props;

  // on mount
  useEffect(() => {
    if (isFirstStep) {
      setShowPreviewPortal(false);
      portalWindow?.close();
      setShowPreviewPane(true);
    } else if (!isAdvancing && !showPreviewPane && !showPreviewPortal) {
      togglePane('showPreviewPane');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const notFinishedExerciseTour = !user?.finishedTours?.exerciseTour;

  const handleFinishTour = () => {
    driver.destroy();
    changeTourStatus({ finishedTours: { exerciseTour: true } });
    createFlashMessage({
      message: FlashMessages.TutorialModeHasEnded,
      type: 'success',
      variables: { dismissible: true }
    });
  };

  const driver = exerciseTour(handleFinishTour);

  useEffect(() => {
    if (notFinishedExerciseTour) {
      driver.drive();
    }

    return () => {
      if (window.location.pathname !== slug) {
        removeFlashMessage();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const challengeFile = getChallengeFile();
  const projectBasedChallenge = hasEditableBoundaries;
  const isReactChallenge = challengeType === 6 ? true : false;
  const displayPreviewPane = hasPreview && showPreviewPane;
  const displayPreviewPortal = hasPreview && showPreviewPortal;
  const displayNotes = projectBasedChallenge ? showNotes && hasNotes : false;
  const displayInstructionsAndTest = showInstructions && showTests;
  const displayEditorConsole = showConsole;
  const {
    codePane,
    editorPane,
    instructionPane,
    testSuitePane,
    notesPane,
    previewPane,
    testsPane
  } = layoutState;

  return (
    <>
      <div className='tools-container'>
        <BreadCrumb
          video={false}
          block={block}
          phase={phase}
          superBlock={superBlock}
          challengeTitle={title}
        />
      </div>

      <div className='desktop-layout'>
        {(projectBasedChallenge || isReactChallenge) && (
          <ActionRow
            hasNotes={hasNotes}
            showConsole={showConsole}
            showNotes={showNotes}
            showInstructions={showInstructions}
            showPreviewPane={showPreviewPane}
            showPreviewPortal={showPreviewPortal}
            togglePane={togglePane}
            showTests={showTests}
            hasPreview={hasPreview}
          />
        )}
        <ReflexContainer orientation='vertical'>
          {(showInstructions || showTests) && (
            <ReflexElement
              flex={instructionPane.flex}
              {...resizeProps}
              {...reflexProps}
            >
              <ReflexContainer orientation='horizontal'>
                {showInstructions && (
                  <ReflexElement>{instructions}</ReflexElement>
                )}
                {displayInstructionsAndTest && (
                  <ReflexSplitter propagate={true} {...resizeProps} />
                )}
                {showTests && (
                  <ReflexElement flex={testSuitePane.flex} {...resizeProps}>
                    {testSuite}
                  </ReflexElement>
                )}
              </ReflexContainer>
            </ReflexElement>
          )}
          {(showInstructions || showTests) && (
            <ReflexSplitter
              propagate={true}
              {...resizeProps}
              {...reflexProps}
            />
          )}

          <ReflexElement
            flex={editorPane.flex}
            {...resizeProps}
            {...reflexProps}
          >
            {challengeFile && (
              <ReflexContainer
                key={challengeFile.fileKey}
                orientation='horizontal'
              >
                <ReflexElement
                  flex={codePane.flex}
                  {...reflexProps}
                  {...resizeProps}
                >
                  <div id='div-reflex-editor'>{editor}</div>
                </ReflexElement>
                {displayEditorConsole && (
                  <ReflexSplitter propagate={true} {...resizeProps} />
                )}
                {displayEditorConsole && (
                  <ReflexElement flex={testsPane.flex} {...resizeProps}>
                    {testOutput}
                  </ReflexElement>
                )}
              </ReflexContainer>
            )}
          </ReflexElement>
          {displayNotes && <ReflexSplitter propagate={true} {...resizeProps} />}
          {displayNotes && (
            <ReflexElement flex={notesPane.flex} {...resizeProps}>
              {notes}
            </ReflexElement>
          )}

          {displayPreviewPane && (
            <ReflexSplitter propagate={true} {...resizeProps} />
          )}
          {displayPreviewPane && (
            <ReflexElement flex={previewPane.flex} {...resizeProps}>
              <ReflexContainer orientation='horizontal'>
                {displayPreviewPane && <ReflexElement>{preview}</ReflexElement>}
              </ReflexContainer>
            </ReflexElement>
          )}
        </ReflexContainer>
        {displayPreviewPortal && (
          <PreviewPortal windowTitle={windowTitle}>{preview}</PreviewPortal>
        )}
      </div>
    </>
  );
};

DesktopLayout.displayName = 'DesktopLayout';

export default connect(mapStateToProps, mapDispatchToProps)(DesktopLayout);
