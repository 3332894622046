import React, { useState } from 'react';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectSignUpLoading, userSelector } from '../../redux/selectors';
import { signUpLocal } from '../../redux/actions';
import { Spacer } from '../helpers';
import { User } from '../../redux/prop-types';
import { apiLocation } from '../../../../config/env.json';

import Input from '../ui/Input';
import Button from '../ui/Button';
import { ButtonTypes } from '../ui/Button/button-types';
import InputPassword from '../ui/input-password';

import GoogleIcon from '../../assets/icons/google-icon';
import CheckedHome from '../../assets/icons/checked-home';
import {
  formatCpfValue,
  formatPhoneValue,
  isCPFValid,
  isPasswordValid
} from '../../utils/data-complement/validate-inputs';
import Marker from '../../assets/icons/marker';

type FormValues = {
  name: string;
  phone: string;
  cpf: string;
  email: string;
  password: string;
};

type SignUpProps = {
  email?: string;
  password?: string;
  name?: string;
  phone?: string;
  cpf?: string;
  signUpLocal: (formValues: FormValues) => void;
  signUpLoading: boolean;
};

const defaultValues = {
  name: '',
  phone: '',
  cpf: '',
  email: '',
  password: ''
};

const mapStateToProps = createSelector(
  userSelector,
  selectSignUpLoading,
  (user: User, signUpLoading: boolean) => ({
    user,
    signUpLoading
  })
);

const mapDispatchToProps = {
  signUpLocal
};

function SignUp(props: SignUpProps) {
  const [formValues, setFormValues] = useState<FormValues>(defaultValues);
  const [validationForm, setValidationForm] =
    useState<FormValues>(defaultValues);

  const { signUpLoading } = props;

  const { t } = useTranslation();

  function handleNameChange(e: React.FormEvent<HTMLInputElement>) {
    const value = (e.target as HTMLInputElement).value.slice(0);
    setValidationForm({ ...validationForm, name: '' });
    return setFormValues({ ...formValues, name: value });
  }

  function handlePhoneChange(e: React.FormEvent<HTMLInputElement>) {
    const value = (e.target as HTMLInputElement).value.slice(0);
    setValidationForm({ ...validationForm, phone: '' });
    return setFormValues({ ...formValues, phone: value });
  }

  function handleCpfChange(e: React.FormEvent<HTMLInputElement>) {
    const value = (e.target as HTMLInputElement).value.slice(0);
    setValidationForm({ ...validationForm, cpf: '' });
    return setFormValues({ ...formValues, cpf: value });
  }

  function handleEmailChange(e: React.FormEvent<HTMLInputElement>) {
    const value = (e.target as HTMLInputElement).value.slice(0);
    setValidationForm({ ...validationForm, email: '' });
    return setFormValues({ ...formValues, email: value });
  }

  function handlePasswordChange(e: React.FormEvent<HTMLInputElement>) {
    const value = (e.target as HTMLInputElement).value.slice(0);
    setValidationForm({ ...validationForm, password: '' });
    return setFormValues({ ...formValues, password: value });
  }

  function isEmptyFields(
    values: FormValues
  ): Partial<Record<keyof FormValues, string>> | undefined {
    const fieldTranslations: Record<keyof FormValues, string> = {
      name: 'Nome completo',
      phone: 'Telefone',
      cpf: 'CPF',
      email: 'E-mail',
      password: 'Senha'
    };
    const fieldsToCheck: (keyof FormValues)[] = [
      'name',
      'phone',
      'cpf',
      'email',
      'password'
    ];
    const validationMessages: Partial<Record<keyof FormValues, string>> = {};

    fieldsToCheck.forEach(field => {
      if (!values[field]) {
        validationMessages[
          field
        ] = `Campo '${fieldTranslations[field]}' está em branco, verfique suas informações e tente novamente.`;
      }
    });

    if (Object.keys(validationMessages).length > 0) {
      return validationMessages;
    }
  }

  function validateFields(
    values: FormValues
  ): Partial<Record<keyof FormValues, string>> | undefined {
    const validationMessages: Partial<Record<keyof FormValues, string>> = {};

    if (!formatPhoneValue(values.phone)) {
      validationMessages.phone =
        'Verifique o número de telefone e tente novamente';
    }
    if (!formatCpfValue(values.cpf)) {
      validationMessages.cpf = 'Verifique o número do CPF e tente novamente.';
    }
    if (!isCPFValid(values.cpf)) {
      validationMessages.cpf = 'Verifique o número do CPF e tente novamente.';
    }
    if (!isPasswordValid(values.password)) {
      validationMessages.password = 'A senha deve ter pelo menos 8 caracteres';
    }

    if (Object.keys(validationMessages).length > 0) {
      return validationMessages;
    }
  }

  function validateForm(values: FormValues) {
    let validationMessages: Partial<Record<keyof FormValues, string>> = {};

    const emptyFieldMessages = isEmptyFields(values);
    if (emptyFieldMessages) {
      validationMessages = { ...validationMessages, ...emptyFieldMessages };
    }

    const invalidFieldMessages = validateFields(values);
    if (invalidFieldMessages) {
      validationMessages = { ...validationMessages, ...invalidFieldMessages };
    }

    if (Object.keys(validationMessages).length > 0) {
      setValidationForm({ ...validationForm, ...validationMessages });
      return false;
    } else {
      return true;
    }
  }

  function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    const { signUpLocal } = props;
    if (validateForm(formValues)) {
      signUpLocal(formValues);
    }
  }
  return (
    <>
      <section className='info-section'>
        <p id='main-title'>{t('register.headings.sign-up-title')}</p>
        <Spacer size='large' />

        <div id='secundary-title-container'>
          <span className='secondary-title'>
            {t('register.headings.sign-up-subtitle01')}
          </span>
          <span className='secondary-title' id='text-marker'>
            <Marker id='marker' />
            {t('register.headings.sign-up-subtitle02')}
          </span>
          <span className='secondary-title'>
            {t('register.headings.sign-up-subtitle03')}
          </span>
        </div>

        <Spacer size='large' />
        <div className='register-benefits'>
          <CheckedHome></CheckedHome>
          <p>{t('register.headings.sign-up-benefits1')}</p>
        </div>
        <div className='register-benefits'>
          <CheckedHome></CheckedHome>
          <p>{t('register.headings.sign-up-benefits2')}</p>
        </div>
        <div className='register-benefits'>
          <CheckedHome></CheckedHome>
          <p>{t('register.headings.sign-up-benefits3')}</p>
        </div>
      </section>

      <section className='register-section'>
        <a className='google-container' href={`${apiLocation}/signin-google`}>
          <GoogleIcon className='google-icon' />
          <p>{t('register.buttons.sign-up-google')}</p>
        </a>

        <Spacer size='medium' />
        <p id='google-or-local'>Ou</p>
        <Spacer size='medium' />

        <form onSubmit={handleSubmit}>
          <Input
            name='name'
            type='text'
            placeholder='Nome completo'
            value={formValues.name}
            onChange={handleNameChange}
            hasError={!!validationForm.name}
            messageError={validationForm.name}
          />
          <Input
            name='phone'
            hasMask={true}
            maskValue='(99) 99999-9999'
            placeholder='Telefone'
            value={formValues.phone}
            onChange={handlePhoneChange}
            hasError={!!validationForm.phone}
            messageError={validationForm.phone}
          />
          <Input
            name='cpf'
            hasMask={true}
            maskValue='999.999.999-99'
            placeholder='CPF'
            value={formValues.cpf}
            onChange={handleCpfChange}
            hasError={!!validationForm.cpf}
            messageError={validationForm.cpf}
          />
          <Input
            name='email'
            type='email'
            placeholder='Email'
            value={formValues.email}
            onChange={handleEmailChange}
            hasError={!!validationForm.email}
            messageError={validationForm.email}
          />
          <InputPassword
            name='password'
            placeholder='Senha'
            value={formValues.password}
            onChange={handlePasswordChange}
            hasError={!!validationForm.password}
            messageError={validationForm.password}
          />
          <div className='form-btn'>
            <Button
              type='submit'
              buttonType={ButtonTypes.Primary}
              style={{ height: '48px', width: '100%' }}
              isLoading={signUpLoading}
            >
              {t('register.buttons.new-account')}
            </Button>
          </div>
        </form>
        <Spacer size='medium' />
        <p id='info-terms'>{t('register.headings.sign-up-accept-terms')}</p>
      </section>
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
