"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTitleFromId = exports.getPathFromID = exports.getCertIds = void 0;
const slugs_1 = require("./slugs");
const idToTitle = new Map(Object.entries({
    '5a553ca864b52e1d8bceea99': 'Fundamentos da Programação Front-End',
    '5a553ca864b52e1d8bceea16': 'Lógica de Programação',
    '639ca6754368246afcadb529': 'HTML, CSS e Javascript',
    '659d43ee4368242406b487a6': 'React'
}));
const idToPath = new Map();
// Keep the timeline slugs the same so
// we don't break existing links
const specialPaths = {
    'Legacy Full Stack': 'Full Stack',
    'Legacy Information Security and Quality Assurance': 'Information Security and Quality Assurance',
    'Scientific Computing with Python': 'Scientific Computing with Python V7',
    'Data Analysis with Python': 'Data Analysis with Python V7',
    'Machine Learning with Python': 'Machine Learning with Python V7',
    'Quality Assurance': 'Quality Assurance V7',
    'Information Security': 'Information Security V7'
};
for (const [id, title] of idToTitle) {
    if (specialPaths[title]) {
        idToPath.set(id, (0, slugs_1.dasherize)(specialPaths[title]));
    }
    else {
        idToPath.set(id, (0, slugs_1.dasherize)(title));
    }
}
const getCertIds = () => idToPath.keys();
exports.getCertIds = getCertIds;
const getPathFromID = (id) => idToPath.get(id);
exports.getPathFromID = getPathFromID;
const getTitleFromId = (id) => idToTitle.get(id);
exports.getTitleFromId = getTitleFromId;
