import { call, put, takeLatest } from 'redux-saga/effects';
import { createFlashMessage } from '../components/Flash/redux';
import {
  getMessageListForUser,
  postCreateMessage,
  postUpdateMessage
} from '../utils/ajax';
import { actionTypes } from './action-types';

function* submitCreateMessage({ payload }) {
  try {
    const { data } = yield call(postCreateMessage, payload);

    if (data?.result?.success) {
      yield put(
        createFlashMessage({ type: 'success', message: data.result.message })
      );
    } else {
      yield put(createFlashMessage({ type: 'danger', message: data.message }));
    }
  } catch (e) {
    yield put(
      createFlashMessage({
        type: 'danger',
        message: 'Indisponível, tente novamente em alguns minutos.'
      })
    );
  }
}

function* submitUpdateMessage({ payload }) {
  try {
    const { data } = yield call(postUpdateMessage, payload);

    if (data?.result?.success) {
      yield put(
        createFlashMessage({ type: 'success', message: data.result.message })
      );
    } else {
      yield put(createFlashMessage({ type: 'danger', message: data.message }));
    }
  } catch (e) {
    console.log('error', e);

    yield put(
      createFlashMessage({
        type: 'danger',
        message: 'Indisponível, tente novamente em alguns minutos.'
      })
    );
  }
}

function* fetchMessagesForUser({ payload }) {
  try {
    const { messageList } = yield call(getMessageListForUser, payload);

    yield put({
      type: actionTypes.fetchMessagesComplete,
      payload: messageList
    });
  } catch (e) {
    console.log('error', e);

    yield put(
      createFlashMessage({
        type: 'danger',
        message: 'Indisponível, tente novamente em alguns minutos.'
      })
    );
  }
}

export function createMessageSaga(types) {
  return [
    takeLatest(types.createMessage, submitCreateMessage),
    takeLatest(types.updateMessage, submitUpdateMessage),
    takeLatest(types.fetchMessages, fetchMessagesForUser)
  ];
}
