import React from 'react';

function House(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={24}
      height={24}
      fill='none'
      {...props}
    >
      <mask
        id='a'
        width={24}
        height={24}
        x={0}
        y={0}
        maskUnits='userSpaceOnUse'
        style={{
          maskType: 'alpha'
        }}
      >
        <path fill='#D9D9D9' d='M0 0h24v24H0z' />
      </mask>
      <g mask='url(#a)'>
        <path
          fill='#484848'
          d='M4.5 20.5v-9.26l-1.868 1.433-.902-1.196L12 3.625l10.269 7.852-.902 1.18L19.5 11.24v9.26h-15ZM6 19h12v-8.9l-6-4.575L6 10.1V19Z'
        />
      </g>
    </svg>
  );
}

House.displayName = 'House';

export default House;
