import React, { ReactNode, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Overlay from 'react-overlays/esm/Overlay';
import { Tooltip } from '@devstart/react-bootstrap';
import {
  discordLocation,
  whatsappSupportNumber
} from '../../../../../config/env.json';

import Discord from '../../../assets/icons/discord';
import HelpFilled from '../../../assets/icons/help-filled';
import WhatsApp from '../../../assets/icons/whatsapp';
import Tutorial from '../../../assets/icons/tutorial';

import './float-button.css';

type Content = {
  icon: ReactNode;
  text: string;
  url?: string;
  action?: () => void;
  title?: string;
};

type FloatButtonProps = {
  readonly contents?: Content[];
  readonly hasTourItem?: boolean;
  readonly onTourStart?: () => void;
};

function FloatButton({
  contents,
  hasTourItem = false,
  onTourStart
}: FloatButtonProps): JSX.Element {
  const [shown, setShown] = useState(false);

  const triggerRef = useRef(null);
  const containerRef = useRef(null);

  const { t } = useTranslation();

  const number = String(whatsappSupportNumber);
  const infoText = t('buttons.error-message-whatsapp');
  const whatsappURL = `https://api.whatsapp.com/send/?phone=${number}&text=${infoText}&type=phone_number&app_absent=0`;

  const defaultContent: Content[] = [
    ...(hasTourItem
      ? [
          {
            icon: <Tutorial />,
            text: t('buttons.tour'),
            action: onTourStart
          }
        ]
      : []),
    {
      icon: <Discord />,
      text: t('buttons.i-have-a-question'),
      url: String(discordLocation),
      title: 'discord'
    },
    {
      icon: <WhatsApp />,
      text: t('buttons.report-an-error'),
      url: whatsappURL,
      title: 'whatsApp'
    }
  ];

  const displayContent = contents ?? defaultContent;

  return (
    <div className='floating-button-container'>
      <button ref={triggerRef} onClick={() => setShown(!shown)}>
        <HelpFilled width={36} height={36} />
      </button>

      <div className='floating-button-overlay-container' ref={containerRef}>
        <Overlay
          show={shown}
          rootClose
          container={containerRef}
          placement='top-end'
          offset={[0, 14]}
          target={triggerRef}
          onHide={() => setShown(false)}
        >
          {({ props }) => (
            <Tooltip id='floating-menu-tooltip' className='bottom-end'>
              <div {...props} className='menu-overlay'>
                <div className='menu-overlay-content'>
                  {displayContent.map(({ icon, text, action, url, title }) => (
                    <a
                      key={text}
                      className='item'
                      href={url}
                      target={url ?? '#'}
                      rel='noopener noreferrer'
                      onClick={() => {
                        setShown(false);
                        action?.();
                        title !== undefined
                          ? window.dataLayer.push({
                              event: 'gtm.click',
                              'gtm.elementClasses': `help-link-${title}`
                            })
                          : null;
                      }}
                    >
                      {icon}
                      <span>{text}</span>
                    </a>
                  ))}
                </div>
              </div>
            </Tooltip>
          )}
        </Overlay>
      </div>
    </div>
  );
}

FloatButton.displayName = 'FloatButton';

export default FloatButton;
