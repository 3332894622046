import React from 'react';

function Tutorial(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <rect
        width='20'
        height='20'
        x='22'
        y='22'
        fill='#FFA1D0'
        rx='10'
        transform='rotate(-180 22 22)'
      ></rect>
      <mask
        id='mask0_2991_1378'
        style={{ maskType: 'alpha' }}
        width='16'
        height='16'
        x='4'
        y='4'
        maskUnits='userSpaceOnUse'
      >
        <path fill='#D9D9D9' d='M4 4H20V20H4z'></path>
      </mask>
      <g mask='url(#mask0_2991_1378)'>
        <path
          fill='#fff'
          d='M11.89 18.628c-.231 0-.436-.06-.613-.183a.965.965 0 01-.373-.48h-.167a.831.831 0 01-.609-.255.831.831 0 01-.255-.609v-1.89A3.825 3.825 0 018.5 13.792 3.817 3.817 0 018 11.89c0-1.085.377-2.004 1.132-2.758C9.886 8.377 10.805 8 11.89 8s2.004.377 2.758 1.132c.755.754 1.132 1.673 1.132 2.758 0 .688-.167 1.325-.5 1.912a3.844 3.844 0 01-1.373 1.408v1.891a.831.831 0 01-.255.609.831.831 0 01-.61.255h-.166a.964.964 0 01-.373.48 1.058 1.058 0 01-.613.183zm-1.153-1.527h2.306v-.54h-2.306v.54zm0-1.05h2.306v-.56h-2.306v.56z'
        ></path>
        <path
          stroke='#fff'
          strokeWidth='0.667'
          d='M6 8l1.333 1.333M18 8l-1.333 1.333M12 4.667v1.885'
        ></path>
      </g>
    </svg>
  );
}

Tutorial.displayName = 'Tutorial';

export default Tutorial;
