import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { switchMap, filter, concat, finalize } from 'rxjs/operators';

import { navigate } from 'gatsby';
import { actionTypes } from './action-types';
import { challengeMetaSelector } from './selectors';
import { closeModal } from './actions';

export default function nextChallengeEpic(action$, state$) {
  return action$.pipe(
    ofType(actionTypes.nextChallenge),
    switchMap(() => {
      const state = state$.value;
      const meta = challengeMetaSelector(state);
      const { nextChallengePath, superBlock } = meta;
      const closeChallengeModal = of(closeModal('completion'));

      let submitter = () => of({ type: 'no-user-signed-in' });

      const pathToNavigateTo = async () => {
        return findPathToNavigateTo(nextChallengePath, superBlock);
      };

      return submitter().pipe(
        concat(closeChallengeModal),
        filter(Boolean),
        finalize(async () => navigate(await pathToNavigateTo()))
      );
    })
  );
}

async function findPathToNavigateTo(nextChallengePath, superBlock) {
  if (nextChallengePath?.includes(superBlock)) {
    return nextChallengePath;
  } else {
    return `/learn/${superBlock}/#${superBlock}-projects`;
  }
}
