import React from 'react';

function ShowPassword(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <>
      <svg
        width='22'
        height='15'
        viewBox='0 0 22 15'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <path
          d='M11.0019 11.5769C12.1349 11.5769 13.0973 11.1803 13.889 10.3871C14.6807 9.59394 15.0765 8.6308 15.0765 7.4977C15.0765 6.36462 14.6799 5.40224 13.8867 4.61058C13.0936 3.81891 12.1304 3.42308 10.9973 3.42308C9.86423 3.42308 8.90185 3.81966 8.11019 4.61282C7.31852 5.40601 6.92269 6.36915 6.92269 7.50225C6.92269 8.63533 7.31927 9.59771 8.11244 10.3894C8.90562 11.181 9.86876 11.5769 11.0019 11.5769ZM10.9996 10.2C10.2496 10.2 9.61209 9.93748 9.08709 9.41248C8.56209 8.88748 8.29959 8.24998 8.29959 7.49998C8.29959 6.74998 8.56209 6.11248 9.08709 5.58748C9.61209 5.06248 10.2496 4.79998 10.9996 4.79998C11.7496 4.79998 12.3871 5.06248 12.9121 5.58748C13.4371 6.11248 13.6996 6.74998 13.6996 7.49998C13.6996 8.24998 13.4371 8.88748 12.9121 9.41248C12.3871 9.93748 11.7496 10.2 10.9996 10.2ZM11.001 14.5C8.70134 14.5 6.60602 13.8657 4.71499 12.5971C2.82397 11.3285 1.43167 9.62946 0.538086 7.49998C1.43167 5.37049 2.82352 3.67146 4.71364 2.40288C6.60374 1.13429 8.6986 0.5 10.9982 0.5C13.2978 0.5 15.3932 1.13429 17.2842 2.40288C19.1752 3.67146 20.5675 5.37049 21.4611 7.49998C20.5675 9.62946 19.1757 11.3285 17.2855 12.5971C15.3954 13.8657 13.3006 14.5 11.001 14.5ZM10.9996 13C12.8829 13 14.6121 12.5041 16.1871 11.5125C17.7621 10.5208 18.9663 9.18331 19.7996 7.49998C18.9663 5.81664 17.7621 4.47914 16.1871 3.48748C14.6121 2.49581 12.8829 1.99998 10.9996 1.99998C9.11625 1.99998 7.38709 2.49581 5.81209 3.48748C4.23709 4.47914 3.03292 5.81664 2.19959 7.49998C3.03292 9.18331 4.23709 10.5208 5.81209 11.5125C7.38709 12.5041 9.11625 13 10.9996 13Z'
          fill='#ADADAD'
        />
      </svg>
    </>
  );
}

ShowPassword.displayName = 'ShowPassword';

export default ShowPassword;
