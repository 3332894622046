import cookies from 'browser-cookies';
import { ManualEmail } from '@components/admin/emails/manuals/types';
import { FormInputs } from '../../redux/prop-types';
import envData from '../../../../config/env.json';

const { apiLocation } = envData;
const base = apiLocation;

const defaultOptions: RequestInit = {
  credentials: 'include'
};

export type ResponseWithData<T> = {
  response: Response;
  data: T;
};

export type FilterOptionsProps = {
  pageNumber?: number;
  pageSize?: number;
  search?: string;
  sort?: string;
  order?: 'ASC' | `DESC`;
};

export interface StandardResponse<T> {
  status: number;
  message: string;
  result: T;
  success: boolean;
}

function getCSRFToken() {
  const token =
    typeof window !== 'undefined' ? cookies.get('csrf_token') : null;
  return token ?? '';
}

async function combineDataWithResponse<T>(response: Response) {
  const data = (await response.json()) as T;
  return { response, data };
}

async function requestWithoutCSRF<T>(
  method: 'POST' | 'PUT' | 'DELETE',
  path: string,
  body?: FormInputs | ManualEmail | BodyInit | null | undefined
): Promise<ResponseWithData<T>> {
  const options: RequestInit = {
    ...defaultOptions,
    method,
    headers: {
      'Content-Type': 'application/json'
    },
    body: body && JSON.stringify(body)
  };

  const response = await fetch(`${base}${path}`, options);
  return combineDataWithResponse(response);
}

async function request<T>(
  method: 'POST' | 'PUT' | 'DELETE',
  path: string,
  body: unknown
): Promise<ResponseWithData<T>> {
  const options: RequestInit = {
    ...defaultOptions,
    method,
    headers: {
      'CSRF-Token': getCSRFToken(),
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };

  const response = await fetch(`${base}${path}`, options);
  return combineDataWithResponse(response);
}

export function post<T = void>(
  path: string,
  body: unknown
): Promise<ResponseWithData<T>> {
  return request('POST', path, body);
}

export function put<T = void>(
  path: string,
  body?: unknown
): Promise<ResponseWithData<T>> {
  return request('PUT', path, body);
}

export function putAuth<T = void>(
  path: string,
  body?: ManualEmail | FormInputs | BodyInit | null | undefined
): Promise<ResponseWithData<T>> {
  return requestWithoutCSRF('PUT', path, body);
}

export function postAuth<T = void>(
  path: string,
  body: ManualEmail | FormInputs | BodyInit | null | undefined
): Promise<ResponseWithData<T>> {
  return requestWithoutCSRF('POST', path, body);
}

export async function get<T>(path: string): Promise<ResponseWithData<T>> {
  const response = await fetch(`${base}${path}`, defaultOptions);

  return combineDataWithResponse(response);
}

export function buildQuery(filter: FilterOptionsProps): string {
  if (!filter) {
    return '';
  }

  const { pageNumber, pageSize, search, sort, order } = filter;

  const query = {
    ...(pageNumber && { pageNumber }),
    ...(pageSize && { pageSize }),
    ...(search && { search }),
    ...(sort && { sort }),
    ...(order && { order })
  };

  const queryValues = Object.entries(query);

  let urlQueryString = '';

  queryValues.forEach(([key, value], index) => {
    urlQueryString += `${key}=${value}${
      index < queryValues.length - 1 ? '&' : ''
    }`;
  });

  return urlQueryString.replace(/\(/g, '\\(').replace(/\)/g, '\\)');
}
