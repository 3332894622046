import React from 'react';
import { ButtonTypes } from '../../ui/Button/button-types';
import Button from '../../ui/Button';
import { Modal } from '../../ui/Modal';

type ModalCtaUnlockAccountProps = {
  readonly show: boolean;
  readonly onHide: () => void;
  readonly handleClick: () => void;
};

function ModalCtaUnlockAccount({
  show,
  onHide,
  handleClick
}: ModalCtaUnlockAccountProps): JSX.Element {
  return (
    <Modal.Root keyboard={true} onHide={onHide} show={show}>
      <Modal.Header closeButton onHide={onHide}>
        Usuário bloqueado por inatividade
      </Modal.Header>
      <Modal.Body>
        <p>
          Sua conta está temporariamente bloqueada devido a inatividade. Para
          continuar o acesso, clique no botão abaixo e realize o desbloqueio de
          sua conta.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={handleClick}
          type='button'
          buttonType={ButtonTypes.Primary}
          width='100%'
        >
          IR PARA PÁGINA DE DESBLOQUEIO
        </Button>
      </Modal.Footer>
    </Modal.Root>
  );
}

ModalCtaUnlockAccount.displayName = 'ModalCtaUnlockAccount';

export default ModalCtaUnlockAccount;
