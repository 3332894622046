import React from 'react';

function RestartTest(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g clipPath='url(#clip0_6417_10707)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M4.75511 10.059C5.04426 8.97923 5.57112 7.97775 6.29714 7.12781C7.02316 6.27787 7.92999 5.60096 8.9513 5.1466C9.9726 4.69224 11.0826 4.47192 12.2 4.50174C13.3174 4.53157 14.414 4.8108 15.4096 5.31899C15.4975 5.36371 15.5933 5.39068 15.6915 5.39837C15.7898 5.40606 15.8887 5.39432 15.9824 5.36382C16.0761 5.33331 16.163 5.28465 16.2379 5.22059C16.3128 5.15654 16.3744 5.07835 16.4191 4.99049C16.4638 4.90264 16.4908 4.80684 16.4985 4.70856C16.5062 4.61028 16.4944 4.51145 16.4639 4.41771C16.4334 4.32397 16.3848 4.23715 16.3207 4.16222C16.2567 4.08729 16.1785 4.02571 16.0906 3.98099C14.196 3.01425 12.0202 2.74976 9.94918 3.23444C7.87814 3.71912 6.04568 4.92164 4.77682 6.62872C3.50796 8.33581 2.8847 10.4371 3.01759 12.56C3.15048 14.6828 4.03093 16.69 5.50278 18.2255C6.97463 19.761 8.94275 20.7256 11.0581 20.9482C13.1734 21.1708 15.2992 20.6371 17.0585 19.4416C18.8177 18.2461 20.0967 16.4662 20.6686 14.4175C21.2405 12.3688 21.0683 10.1838 20.1826 8.24999C20.0997 8.06898 19.9482 7.92834 19.7616 7.859C19.5749 7.78965 19.3684 7.7973 19.1874 7.88024C19.0064 7.96319 18.8657 8.11465 18.7964 8.30129C18.727 8.48794 18.7347 8.69448 18.8176 8.87549C19.3191 9.96967 19.5498 11.1685 19.4904 12.3706C19.4309 13.5728 19.083 14.743 18.4761 15.7823C17.8691 16.8217 17.0209 17.6997 16.0031 18.3422C14.9854 18.9847 13.8279 19.3728 12.6285 19.4738C11.4291 19.5747 10.2231 19.3855 9.11223 18.9222C8.00138 18.4588 7.01836 17.7349 6.24622 16.8116C5.47408 15.8883 4.9355 14.7927 4.67597 13.6174C4.41643 12.4421 4.44358 11.2216 4.75511 10.059Z'
          fill='#290159'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M12.2206 0.21901C12.3612 0.0784067 12.552 -0.000579834 12.7508 -0.000579834C12.9497 -0.000579834 13.1404 0.0784067 13.2811 0.21901L17.0311 3.96901C17.1009 4.03868 17.1563 4.12144 17.1942 4.21256C17.232 4.30368 17.2514 4.40136 17.2514 4.50001C17.2514 4.59866 17.232 4.69634 17.1942 4.78746C17.1563 4.87858 17.1009 4.96134 17.0311 5.03101L13.2811 8.78101C13.2114 8.85064 13.1286 8.90586 13.0375 8.94351C12.9464 8.98116 12.8489 9.0005 12.7503 9.00043C12.6518 9.00036 12.5542 8.98088 12.4632 8.9431C12.3722 8.90533 12.2895 8.84999 12.2198 8.78026C12.0792 8.63943 12.0003 8.4485 12.0004 8.24948C12.0005 8.15093 12.02 8.05337 12.0577 7.96235C12.0955 7.87133 12.1509 7.78864 12.2206 7.71901L15.4396 4.50001L12.2206 1.28101C12.1507 1.21134 12.0953 1.12858 12.0575 1.03746C12.0197 0.946343 12.0002 0.848661 12.0002 0.75001C12.0002 0.651359 12.0197 0.553677 12.0575 0.46256C12.0953 0.371442 12.1507 0.288679 12.2206 0.21901Z'
          fill='#290159'
        />
      </g>
      <defs>
        <clipPath id='clip0_6417_10707'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}

RestartTest.displayName = 'RestartTest';

export default RestartTest;
