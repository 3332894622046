import React from 'react';

function TestFail(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g clipPath='url(#clip0_6945_9526)'>
        <path
          d='M16.3115 3.48813H7.68803C6.74676 3.48813 6.00146 4.22661 6.00146 5.16863V7.0156C6.00146 7.79343 6.5175 8.4502 7.19015 8.65525V20.2803C7.19015 20.3129 7.20377 20.3454 7.20529 20.3794C7.27868 21.6302 8.37657 24 11.9948 24C15.3453 24 16.5922 21.7361 16.7746 20.5391C16.7874 20.4536 16.8086 20.3666 16.8086 20.2803V8.656C17.482 8.45095 17.9981 7.79419 17.9981 7.01636V5.16863C17.9981 4.22661 17.252 3.48813 16.3115 3.48813ZM16.2911 6.42391C16.2911 6.74472 16.0308 7.00501 15.71 7.00501H15.6835C15.3627 7.00501 15.1009 7.26529 15.1009 7.58611V20.2818C15.1009 20.2818 14.7695 22.2953 11.9964 22.2953C9.03334 22.2953 8.8926 20.2818 8.8926 20.2818V7.58535C8.8926 7.26454 8.63232 7.00425 8.3115 7.00425H8.28426C7.96344 7.00425 7.70316 6.74397 7.70316 6.42315V5.77546C7.70316 5.45465 7.96344 5.19436 8.28426 5.19436H15.7069C16.0278 5.19436 16.288 5.45465 16.288 5.77546V6.42391H16.2911Z'
          fill='#E01E1E'
        />
        <path
          d='M11.998 21.4607C13.9426 21.4607 14.1703 19.8354 14.1703 19.8354V14.4534H9.82568V19.8354C9.8272 19.8331 9.92102 21.4607 11.998 21.4607Z'
          fill='#E01E1E'
        />
        <path
          d='M12.2943 12.0382C12.8902 12.0382 13.3733 11.5551 13.3733 10.9592C13.3733 10.3633 12.8902 9.88025 12.2943 9.88025C11.6984 9.88025 11.2153 10.3633 11.2153 10.9592C11.2153 11.5551 11.6984 12.0382 12.2943 12.0382Z'
          fill='#E01E1E'
        />
        <path
          d='M10.9196 8.30946C11.5159 8.30946 11.9993 7.82604 11.9993 7.22973C11.9993 6.63341 11.5159 6.14999 10.9196 6.14999C10.3233 6.14999 9.83984 6.63341 9.83984 7.22973C9.83984 7.82604 10.3233 8.30946 10.9196 8.30946Z'
          fill='#E01E1E'
        />
        <path
          d='M14.159 2.55215C14.7374 2.55215 15.2062 2.08331 15.2062 1.50496C15.2062 0.926609 14.7374 0.457764 14.159 0.457764C13.5807 0.457764 13.1118 0.926609 13.1118 1.50496C13.1118 2.08331 13.5807 2.55215 14.159 2.55215Z'
          fill='#E01E1E'
        />
        <path
          d='M10.4613 1.24392C10.8048 1.24392 11.0833 0.965461 11.0833 0.621961C11.0833 0.278461 10.8048 0 10.4613 0C10.1178 0 9.83936 0.278461 9.83936 0.621961C9.83936 0.965461 10.1178 1.24392 10.4613 1.24392Z'
          fill='#E01E1E'
        />
      </g>
      <defs>
        <clipPath id='clip0_6945_9526'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}

TestFail.displayName = 'TestFail';

export default TestFail;
