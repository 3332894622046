import React from 'react';

function LinkedInLogo(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <>
      <svg
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <rect
          width='15.9971'
          height='15.9971'
          rx='2'
          transform='matrix(-1 0 0 1 16.001 0.00146484)'
          fill='#0A66C2'
        />
        <path
          d='M11.447 13.6322H13.8173L13.8183 9.44468C13.8183 7.38892 13.3753 5.80871 10.9729 5.80871C10.0545 5.77462 9.19184 6.24844 8.72786 7.04177H8.69601V5.99858H6.42055V13.632H8.79082V9.85578C8.79082 8.86 8.9797 7.89558 10.2142 7.89558C11.4312 7.89558 11.447 9.03506 11.447 9.92022V13.6322Z'
          fill='white'
        />
        <path
          d='M2.37036 3.57987C2.3705 4.33955 2.98644 4.95529 3.7461 4.95515C4.11091 4.95509 4.46074 4.8101 4.71865 4.55209C4.97656 4.29409 5.12142 3.94419 5.12135 3.57938C5.12121 2.8197 4.50527 2.20397 3.74561 2.2041C2.98594 2.20424 2.37023 2.82019 2.37036 3.57987Z'
          fill='white'
        />
        <path
          d='M2.5585 13.6322H4.93124V5.99858H2.5585V13.6322Z'
          fill='white'
        />
      </svg>
    </>
  );
}

LinkedInLogo.displayName = 'LinkedInLogo';

export default LinkedInLogo;
