import React, { ReactNode } from 'react';
import { Modal } from '@devstart/react-bootstrap';
import Close2 from '../../../assets/icons/close2';

type HeaderProps = {
  readonly children: ReactNode;
  readonly closeButton?: boolean;
  readonly onHide?: () => void;
};

function Header({
  children,
  closeButton = false,
  onHide
}: HeaderProps): JSX.Element {
  return (
    <Modal.Header
      closeButton={false}
      style={{ justifyContent: closeButton ? 'space-between' : 'flex-end' }}
    >
      {closeButton && (
        <Close2
          {...(onHide && { onClick: onHide })}
          style={{ cursor: 'pointer' }}
        />
      )}
      <Modal.Title>{children}</Modal.Title>
    </Modal.Header>
  );
}

Header.displayName = 'Header';

export default Header;
