import React, { ReactNode } from 'react';
import { Modal } from '@devstart/react-bootstrap';

type FooterProps = {
  readonly children: ReactNode;
};

function Footer({ children }: FooterProps): JSX.Element {
  return <Modal.Footer>{children}</Modal.Footer>;
}

Footer.displayName = 'Footer';

export default Footer;
