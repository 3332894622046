import {
  EmailRecipients,
  RecurringEmail
} from '@components/admin/emails/recurring/types';
import { ManualEmail } from '@components/admin/emails/manuals/types';
import {
  AutomaticEmailsProps,
  RecurringEmailProps,
  FormInputs
} from '../../redux/prop-types';
import {
  FilterOptionsProps,
  ResponseWithData,
  StandardResponse,
  buildQuery,
  get,
  post,
  put
} from './index';

type FilterProps = {
  filter?: FilterOptionsProps;
};

export type UserEmails = {
  email: string;
  id: string;
  name: string;
};

type UserEmailsResponse = {
  data: UserEmails[];
};

export async function getAutomaticEmails({
  filter
}: FilterProps): Promise<StandardResponse<AutomaticEmailsProps>> {
  const query: string = buildQuery(filter as FilterOptionsProps);

  const responseWithData: Promise<
    ResponseWithData<{ result: StandardResponse<AutomaticEmailsProps> }>
  > = get(`/api/automaticEmails/get-emails?${query}`);

  return responseWithData.then(res => res.data.result);
}

export async function getManualEmails({
  filter
}: FilterProps): Promise<StandardResponse<AutomaticEmailsProps>> {
  const query: string = buildQuery(filter as FilterOptionsProps);

  const responseWithData: Promise<
    ResponseWithData<{ result: StandardResponse<AutomaticEmailsProps> }>
  > = get(`/api/manualEmails/get-emails?${query}`);

  return responseWithData.then(res => res.data.result);
}

export async function toggleActiveAutomaticEmail({
  id,
  isActive
}: {
  id: string;
  isActive: boolean;
}): Promise<StandardResponse<AutomaticEmailsProps>> {
  const responseWithData: Promise<
    ResponseWithData<{ result: StandardResponse<AutomaticEmailsProps> }>
  > = put(
    `/api/automaticEmails/toggle-active/${id}?isActive=${isActive.toString()}`
  );

  return responseWithData.then(res => res.data.result);
}

export async function updateAutomaticEmail({
  id,
  data
}: {
  id: string;
  data: FormInputs;
}): Promise<StandardResponse<AutomaticEmailsProps>> {
  const responseWithData: Promise<
    ResponseWithData<{ result: StandardResponse<AutomaticEmailsProps> }>
  > = put(`/api/automaticEmails/edit-email/${id}`, data);
  return responseWithData.then(res => res.data.result);
}

export async function getUsersByEmail(search: string): Promise<unknown[]> {
  try {
    const responseWithData: Promise<ResponseWithData<UserEmailsResponse>> = get(
      `/user/get-emails?search=${search}`
    );

    const {
      data: { data }
    } = await responseWithData;
    return data;
  } catch (e) {
    return [];
  }
}

export async function createManualEmail(
  payload: ManualEmail
): Promise<StandardResponse<AutomaticEmailsProps>> {
  const responseWithData: Promise<
    ResponseWithData<{ result: StandardResponse<AutomaticEmailsProps> }>
  > = post('/api/manualEmails/new-email', payload);
  return responseWithData.then(res => res.data.result);
}

export async function updateManualEmail({
  id,
  data
}: {
  id: string;
  data: FormInputs;
}): Promise<StandardResponse<AutomaticEmailsProps>> {
  const responseWithData: Promise<
    ResponseWithData<{ result: StandardResponse<AutomaticEmailsProps> }>
  > = put(`/api/manualEmails/edit-email/${id}`, data);
  return responseWithData.then(res => res.data.result);
}

export async function cancelManualEmail({
  id
}: {
  id: string;
}): Promise<StandardResponse<{ emailId: string }>> {
  const responseWithData: Promise<
    ResponseWithData<StandardResponse<{ emailId: string }>>
  > = put(`/api/manualEmails/cancel-email/${id}`);
  return responseWithData.then(res => res.data);
}

export async function getRecurringEmails({
  filter
}: FilterProps): Promise<StandardResponse<RecurringEmailProps>> {
  const query: string = buildQuery(filter as FilterOptionsProps);

  const responseWithData: Promise<
    ResponseWithData<{ result: StandardResponse<RecurringEmailProps> }>
  > = get(`/api/recurringEmails/get-emails?${query}`);

  return responseWithData.then(res => res.data.result);
}

export async function createRecurringEmail(
  payload: RecurringEmail
): Promise<StandardResponse<RecurringEmailProps>> {
  const responseWithData: Promise<
    ResponseWithData<{ result: StandardResponse<RecurringEmailProps> }>
  > = post('/api/recurringEmails/new-email', payload);
  return responseWithData.then(res => res.data.result);
}

export async function updateRecurringEmail({
  id,
  data
}: {
  id: string;
  data: RecurringEmailProps;
}): Promise<StandardResponse<RecurringEmailProps>> {
  const responseWithData: Promise<
    ResponseWithData<{ result: StandardResponse<RecurringEmailProps> }>
  > = put(`/api/recurringEmails/edit-email/${id}`, data);
  return responseWithData.then(res => res.data.result);
}

export async function getEmailsByRecipient(
  recipients: EmailRecipients & { pageSize: number; pageNumber: number }
) {
  const responseWithData: Promise<
    ResponseWithData<
      StandardResponse<{
        emails: string[] | { email: string; cc: string; bcc: string }[];
        listSize: number;
        pageNumber: number;
        pageSize: number;
      }>
    >
  > = post('/api/recurringEmails/get-emails-by-recipient', recipients);
  return responseWithData.then(res => {
    return res.data.result;
  });
}

export async function toggleActiveRecurringEmail({
  id,
  isActive
}: {
  id: string;
  isActive: boolean;
}): Promise<StandardResponse<{ emailId: string; isActive: boolean }>> {
  const responseWithData: Promise<
    ResponseWithData<StandardResponse<{ emailId: string; isActive: boolean }>>
  > = put(
    `/api/recurringEmails/toggle-active/${id}?isActive=${isActive.toString()}`
  );
  return responseWithData.then(res => res.data);
}
