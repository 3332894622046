import React from 'react';

import './challenge-title.css';

interface ChallengeTitleProps {
  children: string;
  isCompleted: boolean;
  translationPending: boolean;
  isCentered?: boolean;
}

function ChallengeTitle({
  children,
  isCentered
}: ChallengeTitleProps): JSX.Element {
  return (
    <div className='challenge-title'>
      {isCentered ? (
        <h3 id='centered-title-text'>{children}</h3>
      ) : (
        <h4 id='title-text'>{children}</h4>
      )}
    </div>
  );
}

ChallengeTitle.displayName = 'ChallengeTitle';

export default ChallengeTitle;
