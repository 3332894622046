"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.oldDataVizId = exports.certTypeTitleMap = exports.certTypeIdMap = exports.superBlockCertTypeMap = exports.certSlugTypeMap = exports.completionHours = exports.certIds = exports.SuperBlocks = exports.certTypes = void 0;
exports.certTypes = {
    fundamentosDaProgramacaoFrontEnd: 'isFundamentoDaProgramacaoFrontEnd',
    logicaDeProgramacao: 'isLogicaDeProgramacaoCert',
    htmlCssJavascript: 'isHtmlCssJavascriptCert',
    react: 'isReactCert'
};
var SuperBlocks;
(function (SuperBlocks) {
    SuperBlocks["FundamentoDaProgramacaoFrontEnd"] = "fundamentos-da-programacao-front-end";
    SuperBlocks["LogicaDeProgramacao"] = "logica-de-programacao";
    SuperBlocks["HtmlCssJavascript"] = "html-css-javascript";
    SuperBlocks["React"] = "react";
})(SuperBlocks = exports.SuperBlocks || (exports.SuperBlocks = {}));
exports.certIds = {
    fundamentosDaProgramacaoFrontEndId: '5a553ca864b52e1d8bceea99',
    logicaDeProgramacaoId: '5a553ca864b52e1d8bceea16',
    htmlCssJavascriptId: '639ca6754368246afcadb529',
    reactId: '659d43ee4368242406b487a6'
};
exports.completionHours = {
    [exports.certTypes.fundamentosDaProgramacaoFrontEnd]: 8,
    [exports.certTypes.logicaDeProgramacao]: 80,
    [exports.certTypes.htmlCssJavascript]: 80,
    [exports.certTypes.react]: 80
};
exports.certSlugTypeMap = {
    // modern
    [SuperBlocks.FundamentoDaProgramacaoFrontEnd]: exports.certTypes.fundamentosDaProgramacaoFrontEnd,
    [SuperBlocks.LogicaDeProgramacao]: exports.certTypes.logicaDeProgramacao,
    [SuperBlocks.HtmlCssJavascript]: exports.certTypes.htmlCssJavascript,
    [SuperBlocks.React]: exports.certTypes.react
};
exports.superBlockCertTypeMap = {
    // modern
    [SuperBlocks.FundamentoDaProgramacaoFrontEnd]: exports.certTypes.fundamentosDaProgramacaoFrontEnd,
    [SuperBlocks.LogicaDeProgramacao]: exports.certTypes.logicaDeProgramacao,
    [SuperBlocks.HtmlCssJavascript]: exports.certTypes.htmlCssJavascript,
    [SuperBlocks.React]: exports.certTypes.react
};
exports.certTypeIdMap = {
    [exports.certTypes.fundamentosDaProgramacaoFrontEnd]: exports.certIds.fundamentosDaProgramacaoFrontEndId,
    [exports.certTypes.logicaDeProgramacao]: exports.certIds.logicaDeProgramacaoId,
    [exports.certTypes.htmlCssJavascript]: exports.certIds.htmlCssJavascriptId,
    [exports.certTypes.react]: exports.certIds.reactId
};
exports.certTypeTitleMap = {
    [exports.certTypes.fundamentosDaProgramacaoFrontEnd]: 'Fundamentos da Programação Front-End',
    [exports.certTypes.logicaDeProgramacao]: 'Lógica de Programação',
    [exports.certTypes.htmlCssJavascript]: 'HTML, CSS e Javascript',
    [exports.certTypes.react]: 'React'
};
exports.oldDataVizId = '561add10cb82ac38a17513b3';
