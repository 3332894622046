/* eslint-disable @typescript-eslint/unbound-method */
import React from 'react';
import Helmet from 'react-helmet';
import { User } from '../../redux/prop-types';

import UniversalNav from './components/universal-nav';

import './header.css';

interface HeaderProps {
  fetchState: { pending: boolean };
  user: User;
}
export class Header extends React.Component<HeaderProps> {
  static displayName: string;
  constructor(props: HeaderProps) {
    super(props);
  }

  toggleDisplayMenu(): void {
    this.setState(({ displayMenu }: { displayMenu: boolean }) => ({
      displayMenu: !displayMenu
    }));
  }

  render(): JSX.Element {
    const { fetchState, user } = this.props;
    return (
      <>
        <Helmet>
          <style>{':root{--header-height: 4.45rem;}'}</style>
        </Helmet>
        <header>
          <UniversalNav fetchState={fetchState} user={user} />
        </header>
      </>
    );
  }
}

Header.displayName = 'Header';

export default Header;
