import { put, takeEvery, select } from 'redux-saga/effects';
import {
  userRegistrationStatusSelector,
  userSelector
} from '../../../redux/selectors';
import { isUserAccessAllowed } from '../../../utils/general-functions';
import { openModal } from './actions';
import {
  challengeMetaSelector,
  completedChallengesIdsSelector,
  isBuildEnabledSelector
} from './selectors';

function* verifyNotAllowedChallenge({ payload }) {
  try {
    const isBuildEnabled = yield select(isBuildEnabledSelector);
    if (!isBuildEnabled) {
      return;
    }

    const user = yield select(userSelector);
    const completedChallengeIds = yield select(completedChallengesIdsSelector);
    const userRegistrationStatus = yield select(userRegistrationStatusSelector);
    const challengeMeta = yield select(challengeMetaSelector);

    const isAllowed = yield isUserAccessAllowed(
      challengeMeta,
      completedChallengeIds,
      payload,
      user,
      userRegistrationStatus?.Row?.length > 0
    );

    if (!isAllowed) {
      yield put(openModal('notAllowedChallenge'));
    }
  } catch (e) {
    console.log(e);
  }
}

export function createIsAllowedChallnge(types) {
  return [takeEvery(types.isAllowedChallenge, verifyNotAllowedChallenge)];
}
