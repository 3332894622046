import React from 'react';

function Points(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      width='22'
      height='21'
      viewBox='0 0 22 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g clipPath='url(#clip0_6572_6953)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M4.56875 0.91875C4.62988 0.837246 4.70914 0.771094 4.80027 0.725532C4.89139 0.67997 4.99187 0.65625 5.09375 0.65625H16.9062C17.0081 0.65625 17.1086 0.67997 17.1997 0.725532C17.2909 0.771094 17.3701 0.837246 17.4312 0.91875L21.3372 6.13462C21.5328 6.37744 21.542 6.72525 21.3504 6.97987L11.525 20.0813C11.4639 20.1628 11.3846 20.2289 11.2935 20.2745C11.2024 20.32 11.1019 20.3438 11 20.3438C10.8981 20.3438 10.7976 20.32 10.7065 20.2745C10.6154 20.2289 10.5361 20.1628 10.475 20.0813L0.63125 6.95625C0.546054 6.84266 0.5 6.70449 0.5 6.5625C0.5 6.42051 0.546054 6.28234 0.63125 6.16875L4.56875 0.91875ZM19.5129 5.88656L17.1425 2.72344L16.124 5.8905L19.5129 5.88656ZM14.7446 5.89181L16.0059 1.96875H5.99412L7.25806 5.89969L14.7446 5.89181ZM7.67937 7.21219L14.3232 7.20562L11 17.5429L7.67937 7.21219ZM5.87994 5.901L4.85619 2.72212L2.47006 5.90494L5.87994 5.901ZM2.46744 7.21875L6.29994 7.21481L9.17825 16.1661L2.46875 7.21875H2.46744ZM12.8217 16.1661L15.7014 7.20431L19.5457 7.20038L12.8217 16.1661Z'
          fill='#727272'
        />
      </g>
      <defs>
        <clipPath id='clip0_6572_6953'>
          <rect
            width='21'
            height='21'
            fill='white'
            transform='translate(0.5)'
          />
        </clipPath>
      </defs>
    </svg>
  );
}

Points.displayName = 'Points';

export default Points;
