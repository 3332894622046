import React from 'react';
import { useTranslation } from 'react-i18next';
import { ChallengeTest, Test } from '../../../redux/prop-types';

import TestInitial from '../../../assets/icons/test-initial';
import TestPassed from '../../../assets/icons/test-passed';
import TestFail from '../../../assets/icons/test-fail';

import './test-suite.css';

type TestSuiteTest = {
  err?: string;
  pass?: boolean;
} & ChallengeTest;

function isTestSuiteTest(test: Test): test is TestSuiteTest {
  return 'text' in test;
}

interface TestSuiteProps {
  tests: Test[];
}

function TestSuite({ tests }: TestSuiteProps): JSX.Element {
  const { t } = useTranslation();
  const testSuiteTests = tests.filter(isTestSuiteTest);

  return (
    <>
      <p className='challenge-test-suite-heading'>
        {t('learn.editor-tabs.tests')}
      </p>
      <ul className='challenge-test-suite'>
        {testSuiteTests.map(({ err, pass = false, text = '' }, index) => {
          const isInitial = !pass && !err;
          const statusIcon =
            pass && !err ? (
              <TestPassed className='passed-status' />
            ) : (
              <TestFail className='fail-status' />
            );
          const statusContainer =
            pass && !err ? 'test-container-passed' : 'test-container-wrong';
          // Remove opening/closing <p> so screen reader will read both
          // status message and test text as one block.
          text = text.replace(/^<p>|<\/p>$/g, '');
          return (
            <li
              className={isInitial ? 'test-container' : statusContainer}
              key={text.slice(-6) + String(index)}
            >
              <div className='test-status-icon' aria-hidden='true'>
                {isInitial ? (
                  <TestInitial className='initial-status' />
                ) : (
                  statusIcon
                )}
              </div>
              <div
                className={isInitial ? 'test-output-initial' : 'test-output'}
              >
                <span dangerouslySetInnerHTML={{ __html: text }} />
              </div>
            </li>
          );
        })}
      </ul>
    </>
  );
}

TestSuite.displayName = 'TestSuite';

export default TestSuite;
