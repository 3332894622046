import React from 'react';

function PointsColored(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      width='25'
      height='23'
      viewBox='0 0 25 23'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.15241 0.695994C5.2223 0.604089 5.31294 0.529494 5.41714 0.478118C5.52133 0.426741 5.63623 0.399994 5.75272 0.399994H19.2597C19.3762 0.399994 19.4911 0.426741 19.5953 0.478118C19.6995 0.529494 19.7901 0.604089 19.86 0.695994L24.3263 6.57751C24.55 6.85131 24.5605 7.24351 24.3413 7.53063L13.1065 22.304C13.0366 22.3959 12.946 22.4705 12.8418 22.5219C12.7376 22.5732 12.6227 22.6 12.5062 22.6C12.3897 22.6 12.2748 22.5732 12.1706 22.5219C12.0664 22.4705 11.9758 22.3959 11.9059 22.304L0.650078 7.50399C0.552661 7.3759 0.5 7.22011 0.5 7.05999C0.5 6.89988 0.552661 6.74408 0.650078 6.61599L5.15241 0.695994ZM22.2403 6.29779L19.5299 2.73099L18.3652 6.30223L22.2403 6.29779ZM16.7879 6.30371L18.2302 1.87999H6.78225L8.2275 6.31259L16.7879 6.30371ZM8.70925 7.79259L16.3062 7.78519L12.5062 19.4417L8.70925 7.79259ZM6.65168 6.31407L5.48108 2.72951L2.75267 6.31851L6.65168 6.31407ZM2.74966 7.79999L7.13193 7.79555L10.4231 17.8892L2.75117 7.79999H2.74966ZM14.5893 17.8892L17.882 7.78371L22.2778 7.77927L14.5893 17.8892Z'
        fill={props.fill ?? 'url(#paint0_linear_7256_15424)'}
      />
      <defs>
        <linearGradient
          id='paint0_linear_7256_15424'
          x1='0.132257'
          y1='-0.224379'
          x2='33.1456'
          y2='10.3874'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FF8917' />
          <stop offset='0.473958' stopColor='#ED2885' />
          <stop offset='1' stopColor='#032BDF' />
        </linearGradient>
      </defs>
    </svg>
  );
}

PointsColored.displayName = 'PointsColored';

export default PointsColored;
