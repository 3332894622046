import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import { Grid, Row } from '@devstart/react-bootstrap';
import { useTranslation } from 'react-i18next';
import { navigate } from 'gatsby-link';
import { Spacer } from '../components/helpers';
import createRedirect from '../components/create-redirect';
import SignUp from '../components/sign-up/sign-up';
import SignIn from '../components/sign-in/sign-in';
import {
  isModalGoToUnlockPageOpenSelector,
  isSignedInSelector
} from '../redux/selectors';
import {
  saveCodeRegistration,
  closeModalGoToUnlockPage
} from '../redux/actions';

import './index.css';
import ModalCtaUnlockAccount from '../components/modals/ModalCtaUnlockAccount';

const mapStateToProps = createSelector(
  isSignedInSelector,
  isModalGoToUnlockPageOpenSelector,
  (isSignedIn: boolean, isModalGoToUnlockPageOpen: boolean) => ({
    isSignedIn,
    isModalGoToUnlockPageOpen
  })
);

const mapDispatchToProps = {
  saveCodeRegistration,
  closeModalGoToUnlockPage
};

export interface IndexProps {
  readonly isSignedIn?: boolean;
  readonly isModalGoToUnlockPageOpen: boolean;
  readonly closeModalGoToUnlockPage: () => void;
  readonly saveCodeRegistration: (code: string) => void;
}

interface UTMObject {
  [key: string]: string | null;
}

const RedirectLearn = createRedirect('/learn');

export const removeNacionalStorageInfo = (): void => {
  localStorage.removeItem('devStart:source');
  localStorage.removeItem('devStart:registrationQuiz');
};

function IndexPage({
  isSignedIn,
  isModalGoToUnlockPageOpen,
  closeModalGoToUnlockPage,
  saveCodeRegistration
}: IndexProps): JSX.Element {
  const { t } = useTranslation();
  const [registerSection, setRegisterSection] = useState('active');
  const [logInSection, setLogInSection] = useState('inactive');

  const params = new URLSearchParams(location.search);
  const code = params.get('codigo');
  const utmSource = params.get('utm_source');

  const decodeBase64 = (str: string) => {
    try {
      return decodeURIComponent(
        atob(str)
          .split('')
          .map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
          .join('')
      );
    } catch (e) {
      console.error('Erro ao decodificar base64:', e);
      return '';
    }
  };

  const decodedSearch = decodeBase64(location.search.slice(1));
  const decodedParams = new URLSearchParams(decodedSearch);
  const queryParams: Record<string, string> = {};

  decodedParams.forEach((value, key) => {
    queryParams[key] = value;
  });

  const { source, registrationQuiz } = queryParams;

  if (source && registrationQuiz) {
    localStorage.setItem(
      'devStart:source',
      JSON.stringify(source.toLowerCase())
    );
    localStorage.setItem('devStart:registrationQuiz', registrationQuiz);
  } else {
    removeNacionalStorageInfo();
  }

  if (code !== null) {
    localStorage.setItem('devStart:codigo', code);
    saveCodeRegistration(code);
  }

  if (utmSource !== null) {
    const utmObject: UTMObject = {};
    params.forEach((value, key) => {
      utmObject[key] = value;
    });

    localStorage.setItem('devStart:utm', JSON.stringify(utmObject));
  }

  if (isSignedIn) {
    return <RedirectLearn />;
  }

  return (
    <>
      <Helmet title={`${t('register.title')} | DEVstart`} />
      <Grid className='index-container'>
        <Spacer size='large' />
        <Row className='register-container'>
          <button
            className={
              registerSection === 'active'
                ? 'register-button-active'
                : 'register-button-inactive'
            }
            onClick={() => {
              setRegisterSection('active');
              setLogInSection('inactive');
            }}
          >
            {t('register.buttons.sign-up')}
          </button>
          <button
            className={
              logInSection === 'active'
                ? 'register-button-active'
                : 'register-button-inactive'
            }
            onClick={() => {
              setLogInSection('active');
              setRegisterSection('inactive');
            }}
          >
            {t('register.buttons.sign-in')}
          </button>
        </Row>

        <Spacer size='medium' />
        {registerSection === 'active' ? (
          <div className='sign-container'>
            <SignUp />
          </div>
        ) : (
          <div className='sign-container'>
            <SignIn />
          </div>
        )}
      </Grid>

      <ModalCtaUnlockAccount
        show={isModalGoToUnlockPageOpen}
        onHide={() => closeModalGoToUnlockPage()}
        handleClick={() => {
          closeModalGoToUnlockPage();
          void navigate('/unlock-account');
        }}
      />
    </>
  );
}

IndexPage.displayName = 'IndexPage';

export default connect(mapStateToProps, mapDispatchToProps)(IndexPage);
