import React from 'react';

function Location(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      width='14'
      height='18'
      viewBox='0 0 14 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g clipPath='url(#clip0_6572_7252)'>
        <path
          d='M4.25293 12.9404C4.76084 13.5531 5.2231 14.1986 5.63637 14.8721C5.98922 15.5054 6.13632 15.9349 6.39419 16.6981C6.55234 17.1197 6.69527 17.2456 7.00258 17.2456C7.33744 17.2456 7.48937 17.0315 7.60675 16.7004C7.85051 15.9797 8.04177 15.4297 8.34352 14.9099C8.93564 13.9065 9.6714 13.0149 10.3943 12.158C10.5901 11.9154 11.8554 10.5025 12.4251 9.38762C12.4251 9.38762 13.1253 8.16294 13.1253 6.45256C13.1253 4.85267 12.4348 3.74305 12.4348 3.74305L10.4468 4.24705L9.23949 7.25727L8.9408 7.67235L8.88108 7.74755L8.80165 7.84168L8.66221 7.99203L8.46296 8.18061L7.38788 9.00978L4.70005 10.4789L4.25293 12.9404Z'
          fill='#727272'
        />
        <path
          d='M1.47852 9.18165C2.13451 10.6001 3.39952 11.847 4.25539 12.9413L8.80134 7.84354C8.80134 7.84354 8.16089 8.63646 6.99915 8.63646C5.7051 8.63646 4.65969 7.65822 4.65969 6.42471C4.65969 5.57882 5.19725 4.99772 5.19725 4.99772L2.11135 5.7805L1.47852 9.1816V9.18165Z'
          fill='#727272'
        />
        <path
          d='M8.85137 0.887542C10.3612 1.34838 11.6535 2.31584 12.4351 3.74247L8.80199 7.8411C8.80199 7.8411 9.33956 7.24948 9.33956 6.40885C9.33956 5.14662 8.21681 4.20366 7.00392 4.20366C5.85697 4.20366 5.19824 4.99528 5.19824 4.99528V2.41319L8.85137 0.887497V0.887542Z'
          fill='#727272'
        />
        <path
          d='M2.31152 2.69814C3.21329 1.67677 4.80004 0.625 6.98763 0.625C8.04897 0.625 8.84857 0.888781 8.84857 0.888781L5.19578 4.99715H2.60772L2.31152 2.69819V2.69814Z'
          fill='#727272'
        />
        <path
          d='M1.4785 9.18147C1.4785 9.18147 0.875 8.06266 0.875 6.44184C0.875 4.90994 1.50406 3.57088 2.31126 2.69786L5.19758 4.99723L1.4785 9.18147Z'
          fill='#727272'
        />
      </g>
      <defs>
        <clipPath id='clip0_6572_7252'>
          <rect
            width='12.25'
            height='16.625'
            fill='white'
            transform='translate(0.875 0.625)'
          />
        </clipPath>
      </defs>
    </svg>
  );
}

Location.displayName = 'Location';

export default Location;
