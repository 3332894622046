export enum FlashMessages {
  AccountDeleted = 'flash.account-deleted',
  AddNameSuccess = 'flash.add-name',
  AlreadyClaimed = 'flash.already-claimed',
  CertClaimSuccess = 'flash.cert-claim-success',
  CertificateMissing = 'flash.certificate-missing',
  CertsPrivate = 'flash.certs-private',
  ChallengeSaveTooBig = 'flash.challenge-save-too-big',
  ChallengeSubmitTooBig = 'flash.challenge-submit-too-big',
  CodeSaved = 'flash.code-saved',
  CodeSaveError = 'flash.code-save-error',
  CodeSaveLess = 'flash.code-save-less',
  CompleteProjectFirst = 'flash.complete-project-first',
  DeleteTokenErr = 'flash.delete-token-err',
  EmailValid = 'flash.email-valid',
  HonestFirst = 'flash.honest-first',
  IncompleteSteps = 'flash.incomplete-steps',
  LocalCodeSaved = 'flash.local-code-saved',
  LocalCodeSaveError = 'flash.local-code-save-error',
  NameNeeded = 'flash.name-needed',
  None = '',
  NotEligible = 'flash.not-eligible',
  NotHonest = 'flash.not-honest',
  NotRight = 'flash.not-right',
  ProfilePrivate = 'flash.profile-private',
  ProgressReset = 'flash.progress-reset',
  ProvideUsername = 'flash.provide-username',
  ReallyWeird = 'flash.really-weird',
  ReportSent = 'flash.report-sent',
  SigninSuccess = 'flash.signin-success',
  StartProjectErr = 'flash.start-project-err',
  TutorialModeHasEnded = 'flash.tutorial-mode-has-finished',
  TimelinePrivate = 'flash.timeline-private',
  TokenDeleted = 'flash.token-deleted',
  UpdatedPreferences = 'flash.updated-preferences',
  UpdatedAboutMe = 'flash.updated-about-me',
  UpdatedSocials = 'flash.updated-socials',
  UpdatedSound = 'flash.updated-sound',
  UpdatedThemes = 'flash.updated-themes',
  UpdatedKeyboardShortCuts = 'flash.keyboard-shortcut-updated',
  UpdatedQunicyEmailSubscription = 'flash.subscribe-to-quincy-updated',
  UpdatedPortfolio = 'flash.portfolio-item-updated',
  UpdatedPrivacy = 'flash.privacy-updated',
  UsernameNotFound = 'flash.username-not-found',
  UsernameTaken = 'flash.username-taken',
  UsernameUpdated = 'flash.username-updated',
  UsernameUsed = 'flash.username-used',
  UserNotCertified = 'flash.user-not-certified',
  WrongName = 'flash.wrong-name',
  WrongUpdating = 'flash.wrong-updating',
  WentWrong = 'flash.went-wrong',
  ChallengeWrongAnswer = 'flash.gallenge-wrong-answer',
  ChallengeNotAnswered = 'flash.gallenge-not-answered',
  ChallengeNotAllowed = 'flash.challenge-not-allowed',
  InvalidCPF = 'flash.invalid-cpf',
  InvalidFormField = 'flash.invalid-form-field',
  UnavailableService = 'flash.unavailable-service',
  PaymentNotConfirm = 'flash.payment-not-confirm',
  RegisterUser = 'flash.register-user'
}
