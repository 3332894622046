import React from 'react';

function Power(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={24}
      height={24}
      fill='none'
      {...props}
    >
      <path
        fill='#484848'
        fillRule='evenodd'
        d='M8.367 6.656a7.5 7.5 0 1 0 7.383.066l.75-1.3a9 9 0 1 1-8.862-.079l.73 1.313Z'
        clipRule='evenodd'
      />
      <path
        fill='#484848'
        fillRule='evenodd'
        d='M11.25 12V1.5h1.5V12h-1.5Z'
        clipRule='evenodd'
      />
    </svg>
  );
}

Power.displayName = 'Power';

export default Power;
