/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { CSSProperties, forwardRef } from 'react';
import {
  FormGroup,
  FormControl,
  ControlLabel
} from '@devstart/react-bootstrap';
import InputMask from 'react-input-mask';

import './input.css';

type InputProps = {
  label?: string;
  icon?: React.ReactNode;
  insideIcon?: React.ReactNode;
  hasError?: boolean;
  style?: CSSProperties;
  error?: string | null;
  disabled?: boolean;
  hasMask?: boolean;
  maskValue?: string;
  messageError?: string;
} & React.HTMLAttributes<HTMLInputElement> & { [key: string]: unknown };

const Input = forwardRef<HTMLInputElement, InputProps>(function Input(
  {
    label,
    icon,
    hasError,
    style,
    insideIcon,
    error,
    hasMask,
    maskValue,
    messageError,
    ...rest
  },
  ref
): JSX.Element {
  const borderStyle: CSSProperties = icon
    ? { borderBottomLeftRadius: 0, borderTopLeftRadius: 0 }
    : { borderRadius: '8px' };

  if (
    (rest.name === 'effectiveDate' || rest.name === 'expirationDate') &&
    rest?.value === ''
  ) {
    borderStyle.borderColor = '#ADADAD';
  }

  const hasErrorStyle = hasError
    ? { color: '#DC3545', fontSize: '12px', fontWeight: '400' }
    : {};

  if (hasMask) {
    return (
      <div className='input-container form-group'>
        <InputMask
          className='form-control form-control-input'
          mask={maskValue ?? ''}
          inputRef={ref}
          style={{ ...borderStyle }}
          {...rest}
        />
        <p style={{ ...hasErrorStyle }}>{hasError ? messageError : ''}</p>
      </div>
    );
  }

  return (
    <FormGroup style={style} className='input-container'>
      {label && <ControlLabel>{label}</ControlLabel>}

      <div className={`input-field ${insideIcon ? 'has-feedback' : ''}`}>
        {icon && (
          <span className='input-group-addon icon-container' id='basic-addon1'>
            {icon}
          </span>
        )}
        <FormControl
          style={{ ...borderStyle, ...hasErrorStyle }}
          inputRef={ref}
          {...rest}
        />
        {insideIcon && (
          <div className='form-control-feedback'>{insideIcon}</div>
        )}
      </div>

      {error && <span className='error-message'>{error}</span>}
    </FormGroup>
  );
});

Input.displayName = 'Input';

export default Input;
